import React, { Component } from "react";
import SystemAPI from "../../network/SystemAPI";
import { getLabel } from "../../util/FormatUtil";
import moment from "moment";
import ExemptionAPI from "../../network/ExemptionAPI";
import { sweetalert } from "../../App";

interface ExemptionModalState {
    selectedExemption
    customFieldData?
    ethnicities?
    genders?
    races?
    immunizations?
    exemptionArrowDown?
    childArrowDown?
    parentArrowDown?
    schoolArrowDown?
    fileUploadArrowDown?
    gradeOptions?
    showLoading?
    showInBetween?
    relationships?
    exemptionTypes?
    districts?
    schools?
    Condition?
    PhysicianSignature?
    NPI?
    DenialReason?
    schoolOptions?
    childCareFacilities?
    physicianEmailBeforeEdit?
    exemptionReasons?
}
interface ExemptionModalProps {
    record
    onClose
    onSubmit
}

export default class PhysicianExemptionRecordModal extends Component<ExemptionModalProps, ExemptionModalState> {
    public static ID = "PhysicianExemptionRecordModal";
    constructor(props) {
        super(props);

        this.state = {
            selectedExemption: {},
            showLoading: false,
            immunizations: [],
            DenialReason: "",
            Condition: "",
            PhysicianSignature: "",
            NPI: "",
        };
    }

    componentDidMount(): void {
        $('#PhysicianExemptionRecordModal').on('shown.bs.modal', function () {
            $(document).off('focusin.modal');
        });
        SystemAPI.getAllEthnicities().then(data => {
            this.setState({ ethnicities: data })
        })
        SystemAPI.getAllGenders().then(data => {
            this.setState({ genders: data })
        })
        SystemAPI.getAllRaces().then(data => {
            this.setState({ races: data })
        })
        SystemAPI.getAllImmunizations().then(data => {
            this.setState({ immunizations: data })
        })
        SystemAPI.getAllRelationships().then(data => {
            this.setState({ relationships: data })
        })
        SystemAPI.getAllExemptionTypes().then(data => {
            this.setState({ exemptionTypes: data })
        })
        SystemAPI.getAllSchoolDistricts().then(data => {
            this.setState({ districts: data })
        })
        SystemAPI.getAllSchools().then(data => {
            this.setState({ schools: data.schools, schoolOptions: data.options })
        })
        SystemAPI.getAllChildCareFacilities().then(data => {
            this.setState({ childCareFacilities: data.facilities })
        })
        SystemAPI.getAllPersonalExemptionReasons().then(data => {
            this.setState({ exemptionReasons: data })
        })
    }

    onApproveOrDeny = async (action, denialReason) => {
        const swalHTML = `<p>Please type your full name and NPI# to <strong>${action ? "Approve" : "Deny"
            }</strong> this exemption.</p>
            <label for="swal-input1" style="width: 80%">Full Name</label>
            <input id="swal-input1" class="swal2-input" style="width: 80%; margin-top: 0">
            <label for="swal-input2" style="width: 80%">NPI#</label>
            <input id="swal-input2" class="swal2-input" style="width: 80%; margin-top: 0">
        `;
        sweetalert
            .fire({
                icon: "question",
                confirmButtonText: "Submit",
                showCancelButton: true,
                html: swalHTML,
                preConfirm: () => {
                    if (
                        //@ts-ignore
                        !document.getElementById("swal-input1").value || !document.getElementById("swal-input2").value
                    ) {
                        sweetalert.showValidationMessage(
                            "Please complete all fields before submitting."
                        );
                    } else {
                        return [
                            //@ts-ignore
                            document.getElementById("swal-input1").value, document.getElementById("swal-input2").value,
                        ];
                    }
                },
            })
            .then((result) => {
                if (result.isConfirmed) {
                    let record = {
                        ReqNum: this.props.record.ReqNum,
                        Condition: this.state.Condition,
                        ParentEmail: this.props.record.ParentEmail,
                        ParentPhone: this.props.record.ParentPhone,
                        ParentFirstName: this.props.record.ParentFirstName,
                        ParentLastName: this.props.record.ParentLastName,
                        PatientPortalUID: this.props.record.PatientPortalUID,
                        PhysicianDenialReason: denialReason ? denialReason : null,
                        //@ts-ignore
                        Signature: result.value[0],
                        //@ts-ignore
                        NPI: result.value[1],
                    };
                    if (action) {
                        ExemptionAPI.approveExemption(record).then(async (data) => {
                            if (data.success) {
                                return sweetalert.fire({
                                    icon: "success",
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    allowEnterKey: false,
                                    showConfirmButton: true,
                                    html: `<p>Thank you, ${record.Signature
                                        }. Your <strong>Approval</strong> for this vaccine exemption has been recorded.</p><p>The OSDH Immunization Services department has been notified of your response and will finish reviewing the exemption request.</p>`,
                                }).then(() => {
                                    this.props.onSubmit();
                                });
                            } else {
                                return sweetalert.fire({
                                    icon: "error",
                                    text: data.reason,
                                });
                            }
                        });
                    } else {
                        ExemptionAPI.denyExemption(record).then(async (data) => {
                            if (data.success) {
                                return sweetalert.fire({
                                    icon: "success",
                                    allowOutsideClick: false,
                                    allowEscapeKey: false,
                                    allowEnterKey: false,
                                    showConfirmButton: true,
                                    html: `<p>Thank you, ${record.Signature
                                        }. Your <strong>Denial</strong> for this vaccine exemption has been recorded.</p>`,
                                }).then(() => {
                                    this.props.onSubmit();
                                });
                            } else {
                                return sweetalert.fire({
                                    icon: "error",
                                    text: data.reason,
                                });
                            }
                        });
                    }
                }
            });
    };

    getDenialReason = () =>
        new Promise((resolve, reject) => {
            sweetalert
                .fire({
                    icon: "question",
                    input: "textarea",
                    confirmButtonText: "Submit",
                    showCancelButton: true,
                    html: `<p>Please explain your reason for denying this exemption.</p>`,
                    inputValidator: (value) => {
                        if (!value) {
                            return "You must input a denial reason.";
                        }
                    },
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.setState({ DenialReason: result.value });
                        resolve(result.value);
                    } else {
                        reject(false);
                    }
                });
        });

    getCondition = () =>
        new Promise((resolve, reject) => {
            sweetalert
                .fire({
                    icon: "question",
                    input: "textarea",
                    confirmButtonText: "Submit",
                    showCancelButton: true,
                    html: `<p>Please list the condition(s) that endanger the child.</p>`,
                    inputValidator: (value) => {
                        if (!value) {
                            return "You must input a condition.";
                        }
                    },
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        this.setState({ Condition: result.value });
                        resolve(result.value);
                    } else {
                        reject(false);
                    }
                });
        });

    render() {
        let exemptImmunizations =
            this.props.record.ExemptImmunizations &&
                this.state.immunizations.length
                ? JSON.parse(this.props?.record?.ExemptImmunizations)?.map(
                    (immunization) => {
                        return getLabel(immunization, this.state.immunizations).label;
                    }
                ).join(", ")
                : null;

        return (
            <div
                className={"modal fade form_modal"}
                id={PhysicianExemptionRecordModal.ID}
                tabIndex={-1}
                role="dialog"
                aria-labelledby="result_modal_label"
                aria-hidden="true"
            >
                <div
                    className="modal-dialog modal-lg modal-xl col-8 px-0"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="container-fluid">
                            <div className="modal-header">
                                <h5 className="modal-title" id="result_modal_label">
                                    Exemption Record
                                </h5>
                                <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => this.props.onClose()}
                                >
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form>
                                    <div
                                        className="py-3"
                                        style={{
                                            display: "grid",
                                            gap: "1rem",
                                            gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
                                        }}
                                    >
                                        <div className="d-flex flex-column">
                                            <label className="font-weight-bold">Child Name</label>
                                            <p>{`${this.props.record.ChildFirstName} ${this.props.record.ChildLastName}`}</p>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "0.25rem",
                                            }}
                                        >
                                            <label className="font-weight-bold">Date of Birth</label>
                                            <p>
                                                {moment(this.props.record.ChildDOB)
                                                    .utc()
                                                    .format("MM-DD-YYYY")}
                                            </p>
                                        </div>
                                        <div className="d-flex flex-column">
                                            <label className="font-weight-bold">Parent Name</label>
                                            <p>{`${this.props.record.ParentFirstName} ${this.props.record.ParentLastName}`}</p>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "0.25rem",
                                            }}
                                        >
                                            <label className="font-weight-bold">Parent Phone</label>
                                            <p>
                                                {this.props.record.ParentPhone}
                                            </p>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                gap: "0.25rem",
                                                gridColumn: "span 2",
                                            }}
                                        >
                                            <label className="font-weight-bold">Immunizations</label>
                                            <p>{exemptImmunizations}</p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            {this.props.record.PhysicianStatus === null ?
                                <div className={"card-footer ms-auto d-flex justify-content-end"}>
                                    <button
                                        type="submit"
                                        id="login-button"
                                        name="login-button"
                                        className="btn btn-success text-light mx-3"
                                        onClick={(e) => {
                                            this.getCondition().then((result) => {
                                                result ? this.onApproveOrDeny(1, result) : null;
                                            });
                                        }}
                                    >
                                        Approve
                                    </button>
                                    <button
                                        type="submit"
                                        id="login-button"
                                        name="login-button"
                                        className="btn btn-danger text-light"
                                        onClick={(e) => {
                                            this.getDenialReason().then((result) => {
                                                result ? this.onApproveOrDeny(0, result) : null;
                                            });
                                        }}
                                    >
                                        Deny
                                    </button>
                                </div>
                                : <></>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

