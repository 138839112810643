export interface PasswordConfiguration {
    required: string,
    minLength: {
        value: number,
        message: string
    },
    pattern:{
        value // saved as a string in the database, but needs to be a RegExp when used in the Login component
        message: string
    },
}

// Default Password Strength Requirements
const defaultMinLength = 14
const defaultNumCapitals = 1
const defaultNumSymbols = 1
const defaultNumDigits = 1

// Compose sections for RegExp
const startAnchor = `^`
const defaultUpper = `(?=(.*[A-Z]){${defaultNumCapitals}})`
const defaultSymbol = `(?=(.*[!@#$%^&*]){${defaultNumSymbols}})`
const defaultDigits = `(?=(.*[0-9]){${defaultNumDigits}})`
const defaultChar = `.{${defaultMinLength},}`
const endAnchor = `$`

export const DefaultPasswordConfiguration:PasswordConfiguration = {
    required: "Password is Required.",
    minLength: {
        value: defaultMinLength,
        message: `Password should contain at least ${defaultMinLength} characters`
    },
    pattern:{
        value: startAnchor + defaultUpper + defaultSymbol + defaultDigits + defaultChar + endAnchor,
        message: `Password should contain at least ${defaultNumCapitals} uppercase (A-Z), ${defaultNumSymbols} symbol (!@#$%^&*) and ${defaultNumDigits} digit (0-9)`
    },
}

export interface PasswordConfigurationValues {
    numUpper:string
    numSymbols:string
    numDigits:string
    numChar:string
}

export const DefaultPswdConfigValues:PasswordConfigurationValues = {
    numUpper: defaultNumCapitals.toString(),
    numSymbols:defaultNumSymbols.toString(),
    numDigits: defaultNumDigits.toString(),
    numChar: defaultMinLength.toString()
}