import React, { useRef, useState } from 'react';

interface SkipLinkProps {
  targetId: string;
  label: string;
  onFocus?: () => void;
  onBlur?: () => void;
}

const SkipLink: React.FC<SkipLinkProps> = ({ targetId, label, onFocus, onBlur }) => {
  const [isFocused, setIsFocused] = useState(false);
  const handleClick = () => {
    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      targetElement.focus();
    }
  };

  const handleFocus = () => {
    setIsFocused(true);
    if (onFocus) {
      onFocus();
    }
  };

  const handleBlur = () => {
    setIsFocused(false);
    if (onBlur) {
      onBlur();
    }
  };

  return (
    <a className={`bypass-block ${isFocused ? 'focused' : ''}`} onFocus={handleFocus} onBlur={handleBlur} href={`#${targetId}`} onClick={handleClick}>
      {label}
    </a>
  );
};

export default SkipLink;