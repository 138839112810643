import React from "react";
import ReactQuill from "react-quill";
import Select from "react-select";
import {Service} from "../../types/Service";
import {sweetalert} from "../../App";
import {isBlank, isOnlyWhitespace} from "../../util/ValidationUtil";
import {
    buildRow,
    createCustomFieldDataArray,
    getBoolSelectOptions,
    getBoolSelectVal,
    getEmailReportToOptions,
    getEmailReportToVal,
    getNameFromID,
    handleCloseModal,
    hideModal,
    showModalNoOutsideClick,
    getLabelIDsArray,
    getLabel,
    getSelectLabels,
    getlabelsFromIDs,
} from "../../util/FormatUtil";
import {isEmptyObject} from "jquery";
import {CustomFieldTypesObj, CustomFieldTypesPages} from "../../types/CustomFieldType";
import {CustomFieldBuilder} from "../../customfields/CustomFieldBuilder";
import { QuestionnaireModal } from "./QuestionnaireModal";
// import { WellPlateSettingsModal } from "./WellPlateSettingsModal";
import InBetweenOverlay from "../InBetweenOverlay";
import Overlay from "../Overlay";
import SystemAPI from "../../network/SystemAPI";
import GetField from "../../customfields/GetField";
import ServicesAPI from "../../network/ServicesAPI";

interface ServicesManagementState{
    selectedService:Service
    customFieldData?
    showInBetween
    selectedServiceQuestions?
    showLoading
    fieldTypes:{label,value}[]
    validPages:{label,value}[]
    changesMade:boolean
    isQuestionnaire
    // createdDate?
}
interface ServicesManagementProps{
    selectedService
    tests
    branding
    onSubmit?
    pageCustomQuestions?
    brandingID?
    isGlobal?
    pricing
}
export class ServicesManagementModal extends React.Component<ServicesManagementProps, ServicesManagementState>{
    public static ID = "servicesmanagement";
    constructor(props) {
        super(props);
        this.state = {
            showInBetween: false,
            showLoading: false,
            changesMade: false,
            isQuestionnaire: false,
            fieldTypes:[],
            validPages:[],
            selectedService: {
                ID:  null,
                Name:  "",
                IsEnabled:getBoolSelectVal(0),
                IsLabService:getBoolSelectVal(0),
                EmailReportTo:getEmailReportToVal(0),
                HtmlTemplate: "",
                AdditionalInformation: "",
                ImportantInformation: "",
                InformedConsent: "",
                Summary: "",
                ReleaseOfInformation: "",
                ROIConsentAuth: "",
                CustomFieldData: null,
                ShowInsurance:getBoolSelectVal(0),
                Tests: [],
                AllowMultipleTests:getBoolSelectVal(0),
                PlatingDirection: 0,
                WellplateSettings: [],
                ProductID: null,
                EnablePayments: getBoolSelectVal(0),
                PricingIDs: null
            } as Service
        } as ServicesManagementState
    }

    componentDidMount(): void {
        $(`#${ServicesManagementModal.ID}`).on('shown.bs.modal', function() {
            $(document).off('focusin.modal');
        });
    }


    componentWillReceiveProps(nextProps: Readonly<ServicesManagementProps>, nextContext: any) {

        if(!isEmptyObject(nextProps.selectedService)){
            let service = nextProps.selectedService;
            if(service.hasOwnProperty('IsEnabled')){
            this.setState({
                changesMade: false,
                selectedService: {
                    ID: service.ID,
                    Name: service.Name,
                    IsEnabled: getBoolSelectVal(service.IsEnabled),
                    IsLabService: getBoolSelectVal(service.IsLabService),
                    EmailReportTo: getEmailReportToVal(service.EmailReportTo),
                    HtmlTemplate: service.HtmlTemplate,
                    ImportantInformation: service.ImportantInformation,
                    AdditionalInformation: service.AdditionalInformation ? service.AdditionalInformation : null,
                    InformedConsent: service.InformedConsent,
                    Summary: service.Summary,
                    ReleaseOfInformation: service.ReleaseOfInformation,
                    ROIConsentAuth: service.ROIConsentAuth,
                    CustomFieldData: nextProps.selectedService && nextProps.selectedService.CustomFieldData ? JSON.parse(nextProps.selectedService.CustomFieldData) : null,
                    ShowInsurance:getBoolSelectVal(service.ShowInsurance),
                    Tests: service.Tests ? getlabelsFromIDs(service.Tests, this.props.tests) : [],
                    AllowMultipleTests: getBoolSelectVal(service.AllowMultipleTests),
                    PlatingDirection: service.PlatingDirection ? service.PlatingDirection : 0,
                    WellplateSettings: service.WellplateSettings ? JSON.parse(service.WellplateSettings) : [],
                    ProductID: (this.props.branding) && (service.BrandingID || service.BrandingID === 0) ? this.getProductLabel(service.BrandingID) : null,
                    EnablePayments: getBoolSelectVal(service.EnablePayments),
                    PricingIDs: service.PricingIDs ? getlabelsFromIDs(JSON.parse(service.PricingIDs), this.props.pricing) : []
                } as Service
            });
        } else {
            this.setState({
                changesMade: false,
                selectedService: {
                    ID: service.ID,
                    Name: service.Name,
                    IsEnabled: getBoolSelectVal(0),
                    IsLabService: getBoolSelectVal(0),
                    EmailReportTo: getEmailReportToVal(0),
                    HtmlTemplate: '',
                    ImportantInformation: '',
                    AdditionalInformation: '',
                    InformedConsent: '',
                    Summary: '',
                    ReleaseOfInformation: '',
                    ROIConsentAuth: '',
                    CustomFieldData: null,
                    ShowInsurance: getBoolSelectVal(0),
                    Tests: [],
                    AllowMultipleTests: getBoolSelectVal(0),
                    PlatingDirection: 0,
                    WellplateSettings: [],
                    ProductID: null,
                    EnablePayments: getBoolSelectVal(0),
                    PricingIDs: null
                } as Service
            });
        }
        }
    }
    
    getCustomQuestions(id){
        this.setState({showLoading: true}, async ()=>{
            SystemAPI.getCustomQuestionsByID(id).then(res =>{
                console.log('res in getCustomQuestions', res)
                this.setState({isQuestionnaire: true, selectedServiceQuestions:res.questions.sort((a,b) => a.Order - b.Order), validPages: createCustomFieldDataArray(CustomFieldTypesPages), fieldTypes: createCustomFieldDataArray(CustomFieldTypesObj), showLoading: false}, () => showModalNoOutsideClick(QuestionnaireModal.ID))
            })
            .catch(e => {
                return sweetalert.fire({icon: 'info', title: '', text: "Could not load questions at this time."}).then(res => {
                    hideModal(QuestionnaireModal.ID)
                    this.setState({showLoading: false, showInBetween:false})
                })
            })
        });
    }


    getProductLabel(id){
        let obj = this.props.branding.find(f => f.BrandingID === id)
        let label = {label: obj.BrandingName, value: obj.BrandingID}
        return label;
    }

    getProductOptions(){
        let productArray = [];
        if(this.props.branding && this.props.branding.length > 0){
            if(this.props.isGlobal){
                this.props.branding.map(m => {
                    if(m.ProductID !== 3){
                        productArray.push({label: m.BrandingName, value: m.BrandingID})                    
                    }
                })
            }
            else if(this.props.brandingID){
                let name = this.props.branding.find(f => f.BrandingID === this.props.brandingID && f.ProductID !== 3).BrandingName;
                productArray.push({label: name, value: this.props.brandingID})
            }
        }
        return productArray;
    }

    render(){
        // console.log('props in ServiceMmgtModal', this.props)
        // console.log('state in ServiceMmgtModal', this.state)
        return(
            <>
            <Overlay show_loading={this.state.showLoading} zIndex={100003} />
            <InBetweenOverlay showInBetween={this.state.showInBetween} zIndex={100002} />
            <QuestionnaireModal isQuestionnaire={this.state.isQuestionnaire} validPages={this.state.validPages} fieldTypes={this.state.fieldTypes} selectedService={this.props.selectedService} selectedServiceQuestions={this.state.selectedServiceQuestions} handleInBetween={() => this.setState({showInBetween: false})} 
             />
             {/* <WellPlateSettingsModal handleInBetween={() => this.setState({showInBetween: false})} selectedService={this.state.selectedService} submit={(e) => {           
                this.setState({selectedService: e, changesMade: true})
             }} /> */}
            <div className="modal fade form_modal" id={ServicesManagementModal.ID} tabIndex={-1} role="dialog"
                 aria-labelledby="result_modal_label"
                 aria-hidden="true">
                <div className="modal-dialog modal-lg modal-xl" role="document">
                    <div className="modal-content">
                    <div className="container-fluid">
                        <div className="modal-header">
                            <h5 className="modal-title" id="result_modal_label">Service Management</h5>
                            <button  style={{outline: 'none'}} type="button" className="close" aria-label="Close" onClick={() => {
                                if(this.state.changesMade){
                                    handleCloseModal(ServicesManagementModal.ID)
                                }
                                else{
                                    hideModal(ServicesManagementModal.ID)
                                }
                            }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {buildRow("Name",
                                <input className={"form-control"}
                                       id="Name"
                                       maxLength={50}
                                       autoComplete={"off"}
                                       type={"search"}
                                       name={"Name"}
                                       aria-label="Name"
                                       onChange={(e)=>{
                                           this.setState( (prevState) => ({
                                               changesMade: true,
                                               selectedService: {
                                                   ...prevState.selectedService,
                                                   Name: e.target.value
                                               }
                                           }))
                                       }}
                                       value={this.state.selectedService.Name}
                                />
                            , 'Service Name')}
                            {buildRow("Is Enabled",
                                <Select
                                    isSearchable={true}
                                    placeholder={<div className="accessibilityText">Please Select...</div>}
                                    noOptionsMessage={() => "No option"}
                                    value={ this.state.selectedService.IsEnabled }
                                    aria-label="Is Service Enabled?"
                                    onChange={(e) => this.setState((prevState)=>({
                                        changesMade: true,
                                        selectedService: {
                                            ...prevState.selectedService,
                                            IsEnabled: e
                                        }
                                    }))}
                                    className={"state_select"}
                                    options={getBoolSelectOptions()}
                                />
                            , 'Enable service: Service will not show if it is disabled')}
                            {buildRow("Show Insurance",
                                <Select
                                    isSearchable={true}
                                    placeholder={<div className="accessibilityText">Please Select...</div>}
                                    noOptionsMessage={() => "No option"}
                                    value={ this.state.selectedService.ShowInsurance }
                                    aria-label="Show insurance?"
                                    onChange={(e) => this.setState((prevState)=>({
                                        changesMade: true,
                                        selectedService: {
                                            ...prevState.selectedService,
                                            ShowInsurance: e
                                        }
                                    }))}
                                    className={"state_select"}
                                    options={getBoolSelectOptions()}
                                />
                            , 'Collect insurance information with this service')}
                            {buildRow("Product",
                                <Select
                                    isSearchable={true}
                                    placeholder={<div className="accessibilityText">Please Select...</div>}
                                    noOptionsMessage={() => "No option"}
                                    value={this.state.selectedService.ProductID}
                                    aria-label="Product"
                                    onChange={(e) => this.setState((prevState)=>({
                                        changesMade: true,
                                        selectedService: {
                                            ...prevState.selectedService,
                                            ProductID: e
                                        }
                                    }))}
                                    className={"state_select"}
                                    options={this.getProductOptions()}
                                />)}
                            {buildRow("Is Lab Service",
                                <Select
                                    isSearchable={true}
                                    placeholder={<div className="accessibilityText">Please Select...</div>}
                                    noOptionsMessage={() => "No option"}
                                    value={this.state.selectedService.IsLabService}
                                    aria-label="Is Lab Service?"
                                    onChange={(e) => this.setState((prevState)=>({
                                        changesMade: true,
                                        selectedService: {
                                            ...prevState.selectedService,
                                            IsLabService: e,
                                            PhysicianReviewRequired: {label: 'No', value: false},
                                            AllowMultipleTests: {label: 'No', value: false}
                                        }
                                    }))}
                                    className={"state_select"}
                                    options={getBoolSelectOptions()}
                                />)}
                                {this.state.selectedService && !this.state.selectedService.IsLabService?.value ? 
                                    <div className="invisible"></div>
                                    : buildRow("Email Report To",
                                <Select
                                    isSearchable={true}
                                    placeholder={<div className="accessibilityText">Please Select...</div>}
                                    noOptionsMessage={() => "No option"}
                                    value={this.state.selectedService.EmailReportTo}
                                    aria-label="Email Report to"
                                    onChange={(e) => this.setState((prevState)=>({
                                        changesMade: true,
                                        selectedService: {
                                            ...prevState.selectedService,
                                            EmailReportTo: e
                                        }
                                    }))}
                                    className={"state_select"}
                                    options={getEmailReportToOptions()}
                                />)}
                                {this.state.selectedService && !this.state.selectedService.IsLabService?.value ? 
                                    <div className="invisible"></div>
                                    :
                                buildRow("Allow Multiple Tests",
                                <Select
                                    isSearchable={true}
                                    placeholder={<div className="accessibilityText">Please Select...</div>}
                                    noOptionsMessage={() => "No option"}
                                    value={ this.state.selectedService.AllowMultipleTests }
                                    aria-label="Allow Multiple Tests"
                                    onChange={(e) => this.setState((prevState)=>({
                                        changesMade: true,
                                        selectedService: {
                                            ...prevState.selectedService,
                                            AllowMultipleTests: e,
                                        }
                                    }))}
                                    className={"state_select"}
                                    options={getBoolSelectOptions()}
                                />
                            , 'Allow for multiple tests to be offered with this service')}
                            {buildRow("Tests",
                                <Select
                                    isSearchable={true}
                                    isMulti={true}
                                    placeholder={<div className="accessibilityText">Please Select...</div>}
                                    noOptionsMessage={() => "No option"}
                                    value={ this.state.selectedService.Tests }
                                    aria-label="Tests"
                                    onChange={(e) => this.setState((prevState)=>({
                                        changesMade: true,
                                        selectedService: {
                                            ...prevState.selectedService,
                                            Tests: e
                                        }
                                    }))}
                                    className={"state_select"}
                                    options={this.props.tests}
                                />
                            , 'Tests that will be associated with this service')}
                            {buildRow("Enable Payments",
                                <Select
                                    isSearchable={true}
                                    placeholder={<div className="accessibilityText">Please Select...</div>}
                                    noOptionsMessage={() => "No option"}
                                    value={ this.state.selectedService.EnablePayments }
                                    aria-label="Enable Payments"
                                    onChange={(e) => this.setState((prevState)=>({
                                        changesMade: true,
                                        selectedService: {
                                            ...prevState.selectedService,
                                            EnablePayments: e,
                                            PricingIDs: null
                                        }
                                    }))}
                                    className={"state_select"}
                                    options={getBoolSelectOptions()}
                                />
                            , 'Enable Payments')}
                            {this.state.selectedService && !this.state.selectedService.EnablePayments?.value ?
                            <div className="invisible"></div> : 
                            buildRow("Price",
                                <Select
                                    isSearchable={true}
                                    isMulti={true}
                                    placeholder={<div className="accessibilityText">Please Select...</div>}
                                    noOptionsMessage={() => "No option"}
                                    value={ this.state.selectedService.PricingIDs }
                                    aria-label="Price"
                                    onChange={(e) => this.setState((prevState)=>({
                                        changesMade: true,
                                        selectedService: {
                                            ...prevState.selectedService,
                                            PricingIDs: e
                                        }
                                    }))}
                                    className={"state_select"}
                                    options={this.props.pricing}
                                />
                            , 'Price of service')}
                            {buildRow("Important Information",
                                <ReactQuill
                                    theme="snow"
                                    value={this.state.selectedService.ImportantInformation}
                                    modules={{keyboard: {
                                        bindings: {
                                          tab: {
                                            key: 9,
                                            handler: function (range, context) {
                                              return true;
                                            },
                                          },
                                        },
                                      }}}
                                    onChange={(val) => this.setState((prevState)=>({
                                        changesMade: true,
                                        selectedService: {
                                            ...prevState.selectedService,
                                            ImportantInformation: val
                                        }
                                    }))}
                                />
                            , 'Text to be displayed on the Appointment page in Important Information section')}
                            {buildRow("Release of Information",
                                <ReactQuill
                                    theme="snow"
                                    value={this.state.selectedService.ReleaseOfInformation}
                                    modules={{keyboard: {
                                        bindings: {
                                          tab: {
                                            key: 9,
                                            handler: function (range, context) {
                                              return true;
                                            },
                                          },
                                        },
                                      }}}
                                    onChange={(val) => this.setState((prevState)=>({
                                        changesMade: true,
                                        selectedService: {
                                            ...prevState.selectedService,
                                            ReleaseOfInformation: val
                                        }
                                    }))}
                                />
                            , 'Text to be displayed on the Appointment page in Release of Information section')}
                            {buildRow("ROI Consent Authorization",
                                <ReactQuill
                                    theme="snow"
                                    value={this.state.selectedService.ROIConsentAuth}
                                    modules={{keyboard: {
                                        bindings: {
                                          tab: {
                                            key: 9,
                                            handler: function (range, context) {
                                              return true;
                                            },
                                          },
                                        },
                                      }}}
                                    onChange={(val) => this.setState((prevState)=>({
                                        changesMade: true,
                                        selectedService: {
                                            ...prevState.selectedService,
                                            ROIConsentAuth: val
                                        }
                                    }))}
                                />
                            , 'Text to be displayed when confirming ROI Consent Authorization')}
                            {buildRow("Informed Consent",
                                <ReactQuill
                                    theme="snow"
                                    value={this.state.selectedService.InformedConsent}
                                    modules={{keyboard: {
                                        bindings: {
                                          tab: {
                                            key: 9,
                                            handler: function (range, context) {
                                              return true;
                                            },
                                          },
                                        },
                                      }}}
                                    onChange={(val) => this.setState((prevState)=>({
                                        changesMade: true,
                                        selectedService: {
                                            ...prevState.selectedService,
                                            InformedConsent: val
                                        }
                                    }))}
                                />
                            , 'Text to be displayed on the Appointment page in Summary section when Informed Consent button is pressed')}
                            {buildRow("Summary",
                                <ReactQuill
                                    theme="snow"
                                    value={this.state.selectedService.Summary}
                                    modules={{keyboard: {
                                        bindings: {
                                          tab: {
                                            key: 9,
                                            handler: function (range, context) {
                                              return true;
                                            },
                                          },
                                        },
                                      }}}
                                    onChange={(val) => this.setState((prevState)=>({
                                        changesMade: true,
                                        selectedService: {
                                            ...prevState.selectedService,
                                            Summary: val
                                        }
                                    }))}
                                />
                            , 'Text to be displayed on the Appointment page in Summary section')}
                            {buildRow("Confirmation Description",
                                <ReactQuill
                                    theme="snow"
                                    value={this.state.selectedService.HtmlTemplate}
                                    modules={{keyboard: {
                                        bindings: {
                                          tab: {
                                            key: 9,
                                            handler: function (range, context) {
                                              return true;
                                            },
                                          },
                                        },
                                      }}}
                                    onChange={(val) => this.setState((prevState)=>({
                                        changesMade: true,
                                        selectedService: {
                                            ...prevState.selectedService,
                                            HtmlTemplate: val
                                        }
                                    }))}
                                />
                            , 'Text to be displayed on the Confirmation page after an appointment is made')}
                            {/* {buildRow("Additional Information",
                                <ReactQuill
                                    theme="snow"
                                    value={this.state.selectedService.AdditionalInformation}
                                    onChange={(val) => this.setState((prevState)=>({
                                        changesMade: true,
                                        selectedService: {
                                            ...prevState.selectedService,
                                            AdditionalInformation: val
                                        }
                                    }))}
                                />
                            , 'Text to be displayed on the Confirmation page footer after an appointment is made')} */}
                            {/*Custom Field Data*/}

                                {
                                (this.props.pageCustomQuestions)
                                    ?
                                    <div className="row">
                                        {this.props.pageCustomQuestions.map((val, index) => {
                                            if(val.Options.isActive){
                                                return (
                                                    <div className="col-12">
                                                        <div className="form-group row">
                                                            <GetField 
                                                            val={val} 
                                                            selectedItem={this.state.selectedService ? this.state.selectedService : null} 
                                                            handleChange={(e) => {
                                                                this.setState({changesMade: true, selectedService: e})
                                                            }}  
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            } else { return <></> }
                                        })}
                                    </div>
                                    :
                                    <div className="invisible"></div>
                            }           
                            
                        </div>
                        <div className="modal-footer">
                            <div className="d-flex justify-content-start">
                            {this.state.selectedService.ID && 
                            <>
                                <button type="button" className="btn btn-success" 
                                    onClick={() => {
                                        if(this.state.changesMade){
                                            return sweetalert.fire("","Please submit form to save changes.", "error")
                                        }
                                        this.setState({showInBetween: true})
                                        this.getCustomQuestions(this.state.selectedService.ID)
                                    }}>
                                        View Questionnaire
                                </button>
                            {/* 
                                <button type="button" className="btn btn-info ml-2" 
                                    onClick={() => {
                                        this.setState({showInBetween: true})
                                        showModalNoOutsideClick(WellPlateSettingsModal.ID)
                                    }}
                                    style={{color: 'aliceblue'}}>
                                        Well Plate Settings
                                </button> */}
                            </>
                            }
                            </div>
                            <div className="ml-auto">
                                <button type="button" className="btn btn-primary" onClick={async() => {
                                    let facilityMissingRapidInfo;
                                    if(!this.state.selectedService.IsLabService.value && this.state.selectedService.Tests.length){
                                        //Checks if Facilities that service is assigned to has Rapid Test Info saved. If not, the user must add that info before making this a Rapid Service
                                        await SystemAPI.assignedFacilityHasRapidInfo(this.state.selectedService.ID).then(res =>{
                                            //console.log(res)
                                            //@ts-ignore
                                            facilityMissingRapidInfo = res.facilitiesMissingData

                                        })
                                        
                                    }
                                    if(facilityMissingRapidInfo?.length){
                                        let facilityNames = []
                                        facilityMissingRapidInfo.map((f) => {
                                            facilityNames.push(f.FacilityName)
                                        })
                                        return sweetalert.fire({icon: 'error', title: 'Attention!', html: `<p class="mb-0">Rapid Test Reporting Information needs to be setup for the following facilities before making this a non-lab service:</p><p class="d-flex flex-col"><p class="font-weight-bold">${facilityNames.toString()}</p><p>(This can be completed in Facility Management)<p/></p>`})
                                    }
                                    if(isBlank(this.state.selectedService.Name) ||
                                        isOnlyWhitespace(this.state.selectedService.Name)
                                    ){
                                        return sweetalert.fire("","Name cannot be empty.", "error")
                                    }
                                    if(!this.state.selectedService.IsEnabled){
                                        return sweetalert.fire("", "Please select a value for Is Enabled", "error")
                                    }
                                    if(!this.state.selectedService.ProductID){
                                        return sweetalert.fire("", "Please select a value for Product", "error")
                                    }
                                    if(!this.state.selectedService.IsLabService){
                                        return sweetalert.fire("", "Please select a value for Is Lab Service", "error")
                                    }
                                    if(!this.state.selectedService.EmailReportTo && this.state.selectedService.EmailReportTo !== 0){
                                        return sweetalert.fire("", "Please select a value for Email Report to", "error")
                                    }
                                    if(!this.state.selectedService.ShowInsurance){
                                        return sweetalert.fire("", "Please select a value for Show Insurance", "error")
                                    }
                                    if(!this.state.selectedService.AllowMultipleTests){
                                        return sweetalert.fire("", "Please select a value for Allow Multiple Tests", "error")
                                    }
                                    if(!this.state.selectedService.Tests || this.state.selectedService.Tests.length < 1){
                                        return sweetalert.fire("", "Please select a value for Tests", "error")
                                    }
                                    if(!this.state.selectedService.EnablePayments){
                                        return sweetalert.fire("", "Please select a value for Enable Payments", "error")
                                    }
                                    if(this.state.selectedService.EnablePayments.value && (!this.state.selectedService.PricingIDs || (this.state.selectedService.PricingIDs && this.state.selectedService.PricingIDs.length < 1))){
                                        return sweetalert.fire("", "If Enable Payments is enabled, at least one Price must be selected.", "error")
                                    }
                                    let regex = /<p>(\s|&nbsp;)*<\/p>/i; //find blank space inbetween p tags
                                    if(!this.state.selectedService.ImportantInformation || isBlank(this.state.selectedService.ImportantInformation) || isOnlyWhitespace(this.state.selectedService.ImportantInformation) || this.state.selectedService.ImportantInformation === '<p><br></p>' || regex.test(this.state.selectedService.ImportantInformation)){
                                        return sweetalert.fire("", "Important Information cannot be empty", "error")
                                    }
                                    if(!this.state.selectedService.ReleaseOfInformation || isBlank(this.state.selectedService.ReleaseOfInformation) || isOnlyWhitespace(this.state.selectedService.ReleaseOfInformation) || this.state.selectedService.ReleaseOfInformation === '<p><br></p>' || regex.test(this.state.selectedService.ReleaseOfInformation)){
                                        return sweetalert.fire("", "Release of Information cannot be empty", "error")
                                    }
                                    if(!this.state.selectedService.ROIConsentAuth || isBlank(this.state.selectedService.ROIConsentAuth) || isOnlyWhitespace(this.state.selectedService.ROIConsentAuth) || this.state.selectedService.ROIConsentAuth === '<p><br></p>' || regex.test(this.state.selectedService.ROIConsentAuth)){
                                        return sweetalert.fire("", "ROI Consent Authorization cannot be empty", "error")
                                    }
                                    if(!this.state.selectedService.InformedConsent || isBlank(this.state.selectedService.InformedConsent) || isOnlyWhitespace(this.state.selectedService.InformedConsent) || this.state.selectedService.InformedConsent === '<p><br></p>' || regex.test(this.state.selectedService.InformedConsent)){
                                        return sweetalert.fire("", "Informed Consent cannot be empty", "error")
                                    }
                                    if(!this.state.selectedService.Summary || isBlank(this.state.selectedService.Summary) || isOnlyWhitespace(this.state.selectedService.Summary) || this.state.selectedService.Summary === '<p><br></p>' || regex.test(this.state.selectedService.Summary)){
                                        return sweetalert.fire("", "Summary cannot be empty", "error")
                                    }
                                    if(!this.state.selectedService.HtmlTemplate || isBlank(this.state.selectedService.HtmlTemplate) || isOnlyWhitespace(this.state.selectedService.HtmlTemplate) || this.state.selectedService.HtmlTemplate === '<p><br></p>' || regex.test(this.state.selectedService.HtmlTemplate)){
                                        return sweetalert.fire("", "Confirmation Description cannot be empty", "error")
                                    }
                                    if(this.state.selectedService.AdditionalInformation){
                                        if(isBlank(this.state.selectedService.AdditionalInformation) || isOnlyWhitespace(this.state.selectedService.AdditionalInformation) || regex.test(this.state.selectedService.AdditionalInformation)){
                                            return sweetalert.fire("", "If Additional Information is provided, it cannot be blank spaces", "error")
                                        }
                                    }
                                    if(this.state.selectedService.AdditionalInformation){
                                        if(regex.test(this.state.selectedService.AdditionalInformation)){
                                            return sweetalert.fire("", "If Additional Information is provided, it cannot be blank spaces", "error")
                                        }
                                    }
                                    let invalid = CustomFieldBuilder.validateCustomFields(this.state.selectedService.CustomFieldData, this.props.pageCustomQuestions, this.state.selectedService )

                                    if(invalid){
                                        return;
                                    }

                                    let selectedServiceCopy = JSON.parse(JSON.stringify(this.state.selectedService))

                                    let selectedServiceTestFixed = {
                                        ...selectedServiceCopy,
                                        Tests: getLabelIDsArray(selectedServiceCopy.Tests)
                                    }

                                    if(this.state.selectedService.PricingIDs && this.state.selectedService.PricingIDs.length > 0){
                                        selectedServiceTestFixed = {
                                            ...selectedServiceTestFixed,
                                            PricingIDs: getLabelIDsArray(selectedServiceCopy.PricingIDs)
                                        }
                                    }

                                    this.setState({changesMade: false})

                                    this.props.onSubmit(selectedServiceTestFixed);

                                }}>
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </>
        )
    }
}