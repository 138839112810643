import React from "react";
import Select from "react-select";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
// import TimePicker from 'react-time-picker';
// import DatePicker from "react-date-picker";
import DatePicker from 'react-datepicker'
import { CustomFields } from "../types/CustomFieldType";
import { getCustomFieldDataSelectLabel, getReactSelectAriaLabel, getReactSelectAriaLabelMultiselect, getValueArray } from "../util/FormatUtil";


interface GetFieldProps {
    val
    selectedItem
    handleChange
    fromSamples?
    patientSearchReadOnly?
    fromPatientMgmt?
}
interface GetFieldState {
    selectedItem
}
export default class GetField extends React.Component<GetFieldProps, GetFieldState> {

    constructor(props) {
        super(props)
        this.state = {
            selectedItem: {}
        }

    }

    componentWillReceiveProps(nextProps: Readonly<GetFieldProps>, nextContext: any) {
        if (nextProps.selectedItem) {
            this.setState({ selectedItem: nextProps.selectedItem })
        }
    }

    getField(val) {

        let customFieldDataCopy = this.props.selectedItem.CustomFieldData ? JSON.parse(JSON.stringify(this.props.selectedItem.CustomFieldData)) : {};

        let fieldTypeID = val.FieldTypeID;

        if (fieldTypeID === CustomFields.TEXT_FIELD || fieldTypeID === CustomFields.EMAIL_FIELD) {
            return (
                <>
                    <div className={"col-12 col-md-4"} data-toggle={'tooltip'} data-placement={'top'} title={val.Options.popoverText}>
                        <label className={"col-form-label"} htmlFor={val.DisplayName.replaceAll(' ', '')} style={{ paddingTop: '0.5em', }}>{val.DisplayName}</label>
                    </div>
                    <div className={this.props.fromSamples ? "col-12 col-md-8" : this.props.fromSamples ? "col-12 col-md-8" : "col-12 col-md-8 pl-md-0 pr-md-1"} id={val.DisplayName}>
                        <div className={this.props.fromSamples ? "row mr-md-2" : "row mr-0 ml-0"}>
                            <div className="col-12 p-0">
                                <input type={"search"}
                                    id={val.DisplayName.replaceAll(' ', '')}
                                    readOnly={this.props.patientSearchReadOnly}
                                    // readOnly={this.props.patientSearchReadOnly && !this.props.fromPatientMgmt}
                                    maxLength={val.Options.maxLength}
                                    aria-label={val.DisplayName}
                                    aria-required={val.Options.isRequired}
                                    className={'form-control'}
                                    value={this.props.selectedItem.CustomFieldData && this.props.selectedItem.CustomFieldData[val.DisplayName] ? this.props.selectedItem.CustomFieldData[val.DisplayName] : ''}
                                    autoComplete="off"
                                    onChange={(e) => {
                                        customFieldDataCopy[val.DisplayName] = e.target.value
                                        this.setState((prevState) => ({
                                            selectedItem: {
                                                ...prevState.selectedItem,
                                                CustomFieldData: customFieldDataCopy
                                            }
                                        }),
                                            () => this.props.handleChange(this.state.selectedItem))
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        else if (fieldTypeID === CustomFields.SELECT_FIELD) {
            return (
                <>
                    <div className={"col-12 col-md-4"} data-toggle={'tooltip'} data-placement={'top'} title={val.Options.popoverText}>
                        <label className={"col-form-label"} id={val.DisplayName.replaceAll(' ', '')} htmlFor={val.DisplayName} style={{ paddingTop: '0.5em', }}>{val.DisplayName}</label>
                    </div>
                    <div className={this.props.fromSamples ? "col-12 col-md-8" : "col-12 col-md-8 pl-md-0 pr-md-1"} id={val.DisplayName}>
                        <div className={this.props.fromSamples ? "row mr-2" : "row mr-0 ml-0"}>
                            <div className="col-12 p-0" aria-labelledby={val.DisplayName.replaceAll(' ', '')} tabIndex={this.props.patientSearchReadOnly ? 0 : -1}>
                                <Select
                                    id={val.ID}
                                    isDisabled={this.props.patientSearchReadOnly}
                                    isMulti={val.Options.isMulti}
                                    aria-label={val.Options.isMulti ?
                                        getReactSelectAriaLabelMultiselect(val.DisplayName, (this.props.selectedItem.CustomFieldData && this.props.selectedItem.CustomFieldData[val.DisplayName] ? getCustomFieldDataSelectLabel(this.props.selectedItem.CustomFieldData[val.DisplayName], val) : ''))
                                        : getReactSelectAriaLabel(val.DisplayName, (this.props.selectedItem.CustomFieldData && this.props.selectedItem.CustomFieldData[val.DisplayName] ? getCustomFieldDataSelectLabel(this.props.selectedItem.CustomFieldData[val.DisplayName], val) : ''))}
                                    isSearchable={true}
                                    placeholder={<div className="accessibilityText">Please Select...</div>}
                                    className={"state_select"}
                                    options={val.Options.options}
                                    value={this.props.selectedItem.CustomFieldData && this.props.selectedItem.CustomFieldData[val.DisplayName] ? getCustomFieldDataSelectLabel(this.props.selectedItem.CustomFieldData[val.DisplayName], val) : ''}
                                    onChange={(e) => {
                                        customFieldDataCopy[val.DisplayName] = val.Options.isMulti ? getValueArray(e) : e.value;
                                        this.setState((prevState) => ({
                                            selectedItem: {
                                                ...prevState.selectedItem,
                                                CustomFieldData: customFieldDataCopy
                                            }
                                        }),
                                            () => this.props.handleChange(this.state.selectedItem))
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        else if (fieldTypeID === CustomFields.DATE_FIELD) {
            let newDateObj = this.props.selectedItem.CustomFieldData && this.props.selectedItem.CustomFieldData[val.DisplayName] ? new Date(this.props.selectedItem.CustomFieldData[val.DisplayName]) : null;
            let datePlusSixHours = newDateObj ? newDateObj.setHours(newDateObj.getHours() + 6) : '';
            return (
                <>
                    <div className={"col-12 col-md-4"} data-toggle={'tooltip'} data-placement={'top'} title={val.Options.popoverText}>
                        <label id={val.DisplayName.replaceAll(' ', '')} className={"col-form-label"} htmlFor={val.DisplayName} style={{ paddingTop: '0.5em', }}>{val.DisplayName}</label>
                    </div>
                    <div className={this.props.fromSamples ? "col-12 col-md-8" : "col-12 col-md-8 pl-md-0 pr-md-1"} id={val.DisplayName}>
                        <div className={this.props.fromSamples ? "row mr-2" : "row mr-0 ml-0"}>
                            <div className="col-12 p-0">
                                <DatePicker
                                    id={val.ID}
                                    className={"tabIndex1"}
                                    readOnly={this.props.patientSearchReadOnly}
                                    minDate={new Date('01-01-1000')}
                                    maxDate={new Date('12-31-9999')}
                                    ariaLabelledBy={val.DisplayName.replaceAll(' ', '')}
                                    placeholderText="--/--/----"
                                    selected={datePlusSixHours ? datePlusSixHours : ''}
                                    onChange={(e) => {
                                        customFieldDataCopy[val.DisplayName] = e;
                                        this.setState((prevState) => ({
                                            selectedItem: {
                                                ...prevState.selectedItem,
                                                CustomFieldData: customFieldDataCopy
                                            }
                                        }),
                                            () => this.props.handleChange(this.state.selectedItem))
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        else if (fieldTypeID === CustomFields.TEXTAREA_FIELD) {
            return (
                <>
                    <div className={"col-12 col-md-4"} data-toggle={'tooltip'} data-placement={'top'} title={val.Options.popoverText}>
                        <label className={"col-form-label"} htmlFor={val.DisplayName} style={{ paddingTop: '0.5em', }}>{val.DisplayName}</label>
                    </div>
                    <div className={this.props.fromSamples ? "col-12 col-md-8" : "col-12 col-md-8 pl-md-0 pr-md-1"} id={val.DisplayName}>
                        <div className={this.props.fromSamples ? "row mr-2" : "row mr-0 ml-0"}>
                            <div className="col-12 p-0">
                                <textarea
                                    id={val.ID}
                                    readOnly={this.props.patientSearchReadOnly}
                                    maxLength={val.Options.maxLength}
                                    rows={3}
                                    className={'form-control'}
                                    aria-label={val.DisplayName}
                                    aria-required={val.Options.isRequired}
                                    value={this.props.selectedItem.CustomFieldData && this.props.selectedItem.CustomFieldData[val.DisplayName] ? this.props.selectedItem.CustomFieldData[val.DisplayName] : ''}
                                    autoComplete="off"
                                    onChange={(e) => {
                                        customFieldDataCopy[val.DisplayName] = e.target.value
                                        this.setState((prevState) => ({
                                            selectedItem: {
                                                ...prevState.selectedItem,
                                                CustomFieldData: customFieldDataCopy
                                            }
                                        }),
                                            () => this.props.handleChange(this.state.selectedItem))
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row pt-1 pr-3 justify-content-end">
                            <div className={this.props.selectedItem.CustomFieldData && this.props.selectedItem.CustomFieldData[val.DisplayName] && this.props.selectedItem.CustomFieldData[val.DisplayName].length > 0 ? 'visible' : 'invisible'}>
                                <div style={{ fontSize: '0.8em' }}>{this.props.selectedItem.CustomFieldData && this.props.selectedItem.CustomFieldData[val.DisplayName] && this.props.selectedItem.CustomFieldData[val.DisplayName].length}/{val.Options.maxLength}</div>
                            </div>
                        </div>
                    </div>
                </>
            )

        }
        else if (fieldTypeID === CustomFields.NUMBER_FIELD) {
            return (
                <>
                    <div className={"col-12 col-md-4"} data-toggle={'tooltip'} data-placement={'top'} title={val.Options.popoverText}>
                        <label className={"col-form-label"} htmlFor={val.DisplayName} style={{ paddingTop: '0.5em', }}>{val.DisplayName}</label>
                    </div>
                    <div className={this.props.fromSamples ? "col-12 col-md-8" : "col-12 col-md-8 pl-md-0 pr-md-1"} id={val.DisplayName}>
                        <div className={this.props.fromSamples ? "row mr-2" : "row mr-0 ml-0"}>
                            <div className="col-12 p-0">
                                <input type={"number"}
                                    id={val.ID}
                                    readOnly={this.props.patientSearchReadOnly}
                                    min={val.Options.min}
                                    max={val.Options.max}
                                    aria-label={val.DisplayName}
                                    aria-required={val.Options.isRequired}
                                    className={'form-control'}
                                    value={this.props.selectedItem.CustomFieldData && this.props.selectedItem.CustomFieldData[val.DisplayName] ? this.props.selectedItem.CustomFieldData[val.DisplayName] : ''}
                                    autoComplete="off"
                                    onChange={(e) => {
                                        customFieldDataCopy[val.DisplayName] = e.target.value
                                        this.setState((prevState) => ({
                                            selectedItem: {
                                                ...prevState.selectedItem,
                                                CustomFieldData: customFieldDataCopy
                                            }
                                        }),
                                            () => this.props.handleChange(this.state.selectedItem))
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        else if (fieldTypeID === CustomFields.PHONE_FIELD) {
            return (
                <>
                    <div className={"col-12 col-md-4"} data-toggle={'tooltip'} data-placement={'top'} title={val.Options.popoverText}>
                        <label className={"col-form-label"} htmlFor={val.DisplayName} style={{ paddingTop: '0.5em', }}>{val.DisplayName}</label>
                    </div>
                    <div className={this.props.fromSamples ? "col-12 col-md-8" : "col-12 col-md-8 pl-md-0 pr-md-1"} id={val.DisplayName}>
                        <div className={this.props.fromSamples ? "row mr-2" : "row mr-0 ml-0"}>
                            <div className="col-12 p-0">
                                <PhoneInput
                                    id={val.ID}
                                    readOnly={this.props.patientSearchReadOnly}
                                    placeholder="Enter phone number (10 digit)"
                                    defaultCountry="US"
                                    aria-label={val.DisplayName}
                                    value={this.props.selectedItem.CustomFieldData && this.props.selectedItem.CustomFieldData[val.DisplayName] ? this.props.selectedItem.CustomFieldData[val.DisplayName] : ''}
                                    onChange={(e) => {
                                        customFieldDataCopy[val.DisplayName] = e;
                                        this.setState((prevState) => ({
                                            selectedItem: {
                                                ...prevState.selectedItem,
                                                CustomFieldData: customFieldDataCopy
                                            }
                                        }),
                                            () => this.props.handleChange(this.state.selectedItem))
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        else if (fieldTypeID === CustomFields.CHECKBOX_FIELD) {
            
            return (
                <>
                    <div className={"col-12 col-md-4"} data-toggle={'tooltip'} data-placement={'top'} title={val.Options.popoverText}>
                        <label className={"col-form-label"} htmlFor={val.DisplayName.replaceAll(' ', '')} style={{ paddingTop: '0.5em', }}>{val.DisplayName}</label>
                    </div>
                    <div className={this.props.fromSamples ? "col-12 col-md-8" : this.props.fromSamples ? "col-12 col-md-8" : "col-12 col-md-8 pl-md-0 pr-md-1 d-flex justify-content-center align-items-center"} id={val.DisplayName}>
                        
                                <input
                                    type={"checkbox"}
                                    id={val.DisplayName.replaceAll(' ', '')}
                                    aria-label={val.DisplayName}
                                    aria-required={val.Options.isRequired}
                                    className={'form-check-input'}
                                    checked={this.props.selectedItem.CustomFieldData && this.props.selectedItem.CustomFieldData[val.DisplayName] ? true : false}
                                    autoComplete="off"
                                    onChange={(e) => {
                                        if(this.props.patientSearchReadOnly){
                                            return false;
                                        }
                                        customFieldDataCopy[val.DisplayName] = e.target.value
                                        this.setState((prevState) => ({
                                            selectedItem: {
                                                ...prevState.selectedItem,
                                                CustomFieldData: customFieldDataCopy
                                            }
                                        }),
                                            () => this.props.handleChange(this.state.selectedItem))
                                    }}
                                />
                            
                    </div>
                </>
            )
        }

    }


    render() {
        return (
            <>
                {this.getField(this.props.val)}
            </>
        )
    }
}


