import React from 'react';
import {AdminPages} from "./AdminNav";

interface SidebarProps 
{
    items?
    authPages?
    onChange: (str: string) => void
    backgroundColor:string
    showSidebar
}

class SidebarItems extends React.Component<SidebarProps, any> {


    private routes = [
        // {label: "Testing Check-in", value: "CheckIn"},
        {label: "Samples", value: "Samples"},
        // {label: "Schedule", value: "Schedules"},
        // {label: "Send Alert", value: "Alert"},
        {label: "Submission", value: "Submission"},
        {label: "Facility", value: "Facility"},
        {label: "Audit Logs", value: "AuditLogs"},
        {label: "User", value: "User"},
        {label: "Public Users", value: "Patient"},
        // {label: "Rapid Test", value: "RapidTest"},
        {label: "Test", value: "Test"},
        {label: "Service", value: "Service"},
        {label: "Result", value: "Result"},
        {label: "Communication", value: "Communication"},
        {label: "Custom Fields", value: "CustomFields"},
        {label: "Patient Fields", value: "PatientFields"},
        {label: "Report Information", value: "ReportSettings"},
        {label: "Password Requirements", value: "PasswordRequirements"},
        {label: "File", value: "File"},
        {label: "Banner", value: "Banner"},
        // {label: "Internal Sample Generator", value: "InternalSampleGenerator"},
        {label: "Pricing", value: "Pricing"},
        {label: "Discounts", value: "Discounts"},
        {label: "Transactions", value: "Transactions"},
        {label: "Vaccine Exemptions", value: "ExemptionRecords"},
        {label: "HL7 Messages", value: "HL7Messages"},
    ]

    private reportPages = [AdminPages.AuditLogs];
    private patientMgmtPages = [AdminPages.Patient]
    private recordsPages = [AdminPages.VaccineExemptions];
    private settingsPages = [AdminPages.PasswordRequirements]
    // private settingsPages = [AdminPages.ReportSettings, AdminPages.PasswordRequirements] // Facility not using ReportSettings
    // private submissionPages = [AdminPages.Submission];
    // private samplePages = [AdminPages.Samples]; // - TODO: keep adding pages as needed
    // private pricingPages = [AdminPages.Pricing]
    private userManagementPages = [AdminPages.Facility, AdminPages.User, AdminPages.Communication, AdminPages.File, AdminPages.Banner];
    // private userManagementPages = [AdminPages.Facility, AdminPages.User, AdminPages.Communication, AdminPages.CustomFields,AdminPages.PatientFields, AdminPages.Service, AdminPages.Test, AdminPages.Result, AdminPages.File, AdminPages.Banner, AdminPages.Pricing, AdminPages.Discounts];
    private connectPages = [AdminPages.HL7Messages]; 


    itemBelongsUnderTab(pageName, tabName){
        if(
            this.props.authPages.includes(pageName) &&
            tabName.includes(pageName)
        ){
            let route = this.routes.find(f => f.label === pageName)
            return (
                <li className={"sidebar-list-item"} role='listitem' aria-hidden={!this.props.showSidebar ? "true" : "false"} style={{backgroundColor: this.props.backgroundColor}}>
                    <a className={'navSelect'} tabIndex={!this.props.showSidebar ? -1 : 0} aria-hidden={!this.props.showSidebar ? "true" : "false"} href={`/admin/${route.value}`}
                       onClick={() => this.props.onChange(pageName)}>
                        {pageName}
                    </a>
                </li>
            );
        }

        return (<React.Fragment />);
    }

    render(){
        // const buildAppointmentsTab = this.props.authPages.some(authPage => this.patientPages.includes(authPage));
        // const buildPodsTab = this.props.authPages.some(authPage => this.podPages.includes(authPage));
        // const buildLabsTab = this.props.authPages.some(authPage => this.labPages.includes(authPage));
        const buildReportsTab = this.props.authPages.some(authPage => this.reportPages.includes(authPage));
        // const buildReceivingTab = this.props.authPages.some(authPage => this.receivingPages.includes(authPage));
        const buildSettingsTab = this.props.authPages.some(authPage => this.settingsPages.includes(authPage))
        const buildPatientsTab = this.props.authPages.some(authPage => this.patientMgmtPages.includes(authPage))
        const buildRecordsTab = this.props.authPages.some(authPage => this.recordsPages.includes(authPage))
        // const buildSubmissionTab = this.props.authPages.some(authPage => this.submissionPages.includes(authPage));
        const buildUserMgmtTab = this.props.authPages.some(authPage => this.userManagementPages.includes(authPage));
        // const buildSamplesTab = this.props.authPages.some(authPage => this.samplePages.includes(authPage));
        const buildConnectTab = this.props.authPages.some(authPage => this.connectPages.includes(authPage));

        return (
            <main className={"sidebar-wrapper"} >

                <ul className={"list-unstyled"} id={"menu"}>
                    {/* { buildSubmissionTab ?
                        <li className={"sidebar-header"} aria-hidden={!this.props.showSidebar ? "true" : "false"} id={"submissions-list"}>
                            <a href={'#'} className={"navTag white"} aria-label='Submission Menu' id={"submissions-tab"} tabIndex={!this.props.showSidebar ? -1 : 0} aria-hidden={!this.props.showSidebar ? "true" : "false"} style={{backgroundColor: this.props.backgroundColor}}>
                                <span className='navIconWrapper'>
                                    <img src={"https://storage.googleapis.com/streamline-bucket/appointment.png"} alt="" className='navIcon'/>
                                </span>
                                Submission
                                <i id={"patients-caret"} className="fa fa-xs fa-caret-down nav-caret"/>
                            </a>
                            <ul className={"list-unstyled"} role='list'>
                                {this.props.items.map( t => {
                                    return this.itemBelongsUnderTab(t, this.submissionPages)
                                })}
                            </ul>
                        </li> :
                        <React.Fragment />
                    } */}
                    {/* { buildPodsTab ?
                        <li className={"sidebar-header"} id={"pods-list"}>
                            <a href={'#!'} className={"navTag primary-black-bg white"} id={"pods-tab"}>
                                <span className='navIconWrapper'>
                                    <img src={"https://storage.googleapis.com/streamline-bucket/pods.png"} alt="" className='navIcon'/>
                                </span>
                                Schedules
                                <i id={"pods-caret"} className="fa fa-xs fa-caret-down nav-caret"/>
                            </a>
                            <ul className={"list-unstyled"}>
                                {this.props.items.map( t => {
                                    return this.itemBelongsUnderTab(t, this.podPages)
                                })}
                            </ul>
                        </li> :
                        <React.Fragment />
                    } */}
                    {/* { buildSamplesTab ?
                        <li className={"sidebar-header"} aria-hidden={!this.props.showSidebar ? "true" : "false"} id={"samples-list"}>
                            <a href={"#"} className={"navTag primary-black-bg white"} aria-label='Samples Menu' role='list' tabIndex={!this.props.showSidebar ? -1 : 0} id={"samples-tab"} aria-hidden={!this.props.showSidebar ? "true" : "false"} style={{backgroundColor: this.props.backgroundColor}}>
                                <span className='navIconWrapper'>
                                    <img src={"https://storage.googleapis.com/streamline-bucket/samples.png"} alt="" className='navIcon'/>
                                </span>
                                Samples
                                <i id={"samples-caret"} className="fa fa-xs fa-caret-down nav-caret"/>
                            </a>
                            <ul className={"list-unstyled"}>
                                {this.props.items.map( t => {
                                    return this.itemBelongsUnderTab(t, this.samplePages)
                                })}
                            </ul>
                        </li> :
                        <React.Fragment />
                    } */}
                    { buildRecordsTab  ?
                        <li className={"sidebar-header"} aria-hidden={!this.props.showSidebar ? "true" : "false"} id={"records-list"} >
                            <a href={'#'} className={"navTag primary-black-bg white"} aria-label='Records Menu' role='list' tabIndex={!this.props.showSidebar ? -1 : 0} id={"records-tab"} aria-hidden={!this.props.showSidebar ? "true" : "false"} style={{backgroundColor: this.props.backgroundColor}}>
                                <span className='navIconWrapper'>
                                    <img src={"https://storage.googleapis.com/streamline-bucket/people.png"} alt="" className='navIcon'/>
                                </span>
                                    Records 
                                <i id={"records-caret"} className="fa fa-xs fa-caret-down nav-caret"/>
                            </a>
                            <ul className={"list-unstyled"}>
                                {this.props.items.map( t => {
                                    return this.itemBelongsUnderTab(t, this.recordsPages)
                                })}
                            </ul>
                        </li> :
                        <React.Fragment />
                    }
                    { buildPatientsTab  ?
                        <li className={"sidebar-header"} aria-hidden={!this.props.showSidebar ? "true" : "false"} id={"patients-list"} >
                            <a href={'#'} className={"navTag primary-black-bg white"} aria-label='Patients Menu' role='list' tabIndex={!this.props.showSidebar ? -1 : 0} id={"patients-tab"} aria-hidden={!this.props.showSidebar ? "true" : "false"} style={{backgroundColor: this.props.backgroundColor}}>
                                <span className='navIconWrapper'>
                                    <img src={"https://storage.googleapis.com/streamline-bucket/people.png"} alt="" className='navIcon'/>
                                </span>
                                    Public Users
                                <i id={"patients-caret"} className="fa fa-xs fa-caret-down nav-caret"/>
                            </a>
                            <ul className={"list-unstyled"}>
                                {this.props.items.map( t => {
                                    return this.itemBelongsUnderTab(t, this.patientMgmtPages)
                                })}
                            </ul>
                        </li> :
                        <React.Fragment />
                    }
                    
                    {/* { buildLabsTab ?
                        <li className={"sidebar-header"} id={"lab-list"}>
                            <a href={'#!'} className={"navTag primary-black-bg white"} id={"lab-tab"}>
                                <span className='navIconWrapper'>
                                    <img src={"https://storage.googleapis.com/streamline-bucket/lab.png"} alt="" className='navIcon'/>
                                </span>
                                Lab
                                <i id={"lab-caret"} className="fa fa-xs fa-caret-down nav-caret"/>
                            </a>
                            <ul className={"list-unstyled"}>
                                {this.props.items.map( t => {
                                    return this.itemBelongsUnderTab(t, this.labPages)
                                })}
                            </ul>
                        </li> :
                        <React.Fragment />
                    } */}
                    { buildReportsTab ?
                        <li className={"sidebar-header"} aria-hidden={!this.props.showSidebar ? "true" : "false"} id={"reports-list"}>
                            <a href={'#'} className={"navTag primary-black-bg white"} tabIndex={!this.props.showSidebar ? -1 : 0} aria-label='Reports Menu' role='list' id={"reports-tab"} aria-hidden={!this.props.showSidebar ? "true" : "false"} style={{backgroundColor: this.props.backgroundColor}}>
                                <span className='navIconWrapper'>
                                    <img src={"https://storage.googleapis.com/streamline-bucket/report.png"} alt="" className='navIcon'/>
                                </span>
                                Reports
                                <i id={"reports-caret"} className="fa fa-xs fa-caret-down nav-caret"/>
                            </a>
                            <ul className={"list-unstyled"}>
                                {this.props.items.map( t => {
                                    return this.itemBelongsUnderTab(t, this.reportPages)
                                })}
                            </ul>
                        </li> :
                        <React.Fragment />
                    }
                    {/* { buildReceivingTab ?
                        <li className={"sidebar-header"} id={"receiving-list"}>
                            <a href={'#!'} className={"navTag primary-black-bg white"} id={"receiving-tab"}>
                                <span className='navIconWrapper'>
                                    <img src={"https://storage.googleapis.com/streamline-bucket/receiving.png"} alt="" className='navIcon'/>
                                </span>
                                Receiving
                                <i id={"receiving-caret"} className="fa fa-xs fa-caret-down nav-caret"/>
                            </a>
                            <ul className={"list-unstyled"}>
                                {this.props.items.map( t => {
                                    return this.itemBelongsUnderTab(t, this.receivingPages)
                                })}
                            </ul>
                        </li> :
                        <React.Fragment />
                    } */}
                    { buildUserMgmtTab ?
                        <li className={"sidebar-header"} aria-hidden={!this.props.showSidebar ? "true" : "false"} id={"user-mgmt-list"}>
                            <a href={'#'} className={"navTag primary-black-bg white"} tabIndex={!this.props.showSidebar ? -1 : 0} aria-label='Setup Menu' role='list' id={"user-mgmt-tab"} aria-hidden={!this.props.showSidebar ? "true" : "false"} style={{backgroundColor: this.props.backgroundColor}}>
                                <span className='navIconWrapper'>
                                    <img src={"https://storage.googleapis.com/streamline-bucket/setup.png"} alt="" className='navIcon'/>
                                </span>
                                Setup
                                <i id={"user-mgmt-caret"} className="fa fa-xs fa-caret-down nav-caret"/>
                            </a>
                            <ul className={"list-unstyled"}>
                                {this.props.items.map( t => {
                                    return this.itemBelongsUnderTab(t, this.userManagementPages)
                                })}
                            </ul>
                        </li> :
                        <React.Fragment />
                    }
                    { buildSettingsTab ?
                        <li className={"sidebar-header"} aria-hidden={!this.props.showSidebar ? "true" : "false"} id={"settings-list"}>
                            <a href={'#'} className={"navTag primary-black-bg white"} tabIndex={!this.props.showSidebar ? -1 : 0} aria-label='Settings Menu' role='list' id={"settings-tab"} aria-hidden={!this.props.showSidebar ? "true" : "false"} style={{backgroundColor: this.props.backgroundColor}}>
                                <span className='navIconWrapper'>
                                    <img src={"https://storage.googleapis.com/streamline-bucket/settings.png"} alt="" className='navIcon'/>
                                </span>
                                Settings
                                <i id={"user-mgmt-caret"} className="fa fa-xs fa-caret-down nav-caret"/>
                            </a>
                            <ul className={"list-unstyled"}>
                                {this.props.items.map( t => {
                                    return this.itemBelongsUnderTab(t, this.settingsPages)
                                })}
                            </ul>
                        </li> :
                        <React.Fragment />
                    }
                     { buildConnectTab ?
                        <li className={"sidebar-header"} aria-hidden={!this.props.showSidebar ? "true" : "false"} id={"connect-list"}>
                            <a href={'#'} className={"navTag primary-black-bg white"} tabIndex={!this.props.showSidebar ? -1 : 0} aria-label='Connect Menu' role='list' id={"connect-tab"} aria-hidden={!this.props.showSidebar ? "true" : "false"} style={{backgroundColor: this.props.backgroundColor}}>
                                <span className='navIconWrapper'>
                                    <img src={"https://storage.googleapis.com/streamline-bucket/connect.svg"} alt="" className='navIcon'/>
                                </span>
                                Connect
                                <i id={"connect-caret"} className="fa fa-xs fa-caret-down nav-caret"/>
                            </a>
                            <ul className={"list-unstyled"}>
                                {this.props.items.map( t => {
                                    return this.itemBelongsUnderTab(t, this.connectPages)
                                })}
                            </ul>
                        </li> :
                        <React.Fragment />
                    }

                </ul>
            </main>
        );
    }
}

export default SidebarItems