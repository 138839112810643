import {initializeApp} from "firebase/app"
import {browserLocalPersistence, initializeAuth} from "firebase/auth"

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig)
const auth = initializeAuth(app,{
    persistence:[browserLocalPersistence]
})
auth.tenantId = process.env.REACT_APP_FIREBASE_TENANT_ID;;
export {app, auth}