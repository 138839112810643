import React from 'react';
import TableBase, {Column, SimpleTableComponentProps} from "./TableBase";
import SimpleHeader from "./SimpleHeader";
import SimpleBody from "./SimpleBody";
interface SimpleTableState{

}


interface SimpleTableProps extends SimpleTableComponentProps{
    prependRows?:string
    appendRows?:any
    table_style?:string
    evenWidthHeaders?:boolean
    columnClickedCallback?:(column:Column)=>any
}

class SimpleTable extends React.Component<SimpleTableProps, SimpleTableState> {

    render(): React.ReactElement | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
         return (
             <TableBase colCount={this.props.columns.length} rowCount={this.props.table_data.length} table_style={this.props.table_style ? this.props.table_style : null}>
                 <SimpleHeader evenWidthHeaders={this.props.evenWidthHeaders} columnClickedCallback={this.props.columnClickedCallback} columns={this.props.columns}/>
                 <SimpleBody appendRows={this.props.appendRows} rowBuilder={this.props.rowBuilder} columns={this.props.columns} table_data={this.props.table_data}/>
             </TableBase>
         );
    }
}
export default SimpleTable;