import {ComposedValidationEntry, ValidationEntry, ValidationFunction} from "./Validator";
import {calculateAge, isBlank, isOnlyWhitespace} from "../util/ValidationUtil";
import {isPossiblePhoneNumber} from 'react-phone-number-input'

export class Validators{

    public static requireNotBlankValidator(keyOverride?:string):ValidationFunction<any>{
        return (key:string, attribute:string, obj:any) =>{
            return isBlank(attribute) || isOnlyWhitespace(attribute) ? {success:false, error:`${keyOverride ? keyOverride : key} cannot be blank.`} : {success:true, error:''};
        };
    }

    public static requireIsInt(keyOverride?:string):ValidationFunction<any>{
        return (key:string, attribute:any, obj:any) =>{
            return isNaN(parseInt(attribute)) ? {success:false, error:`${keyOverride ? keyOverride : key} must be an integer.`} : {success:true, error:''};
        };
    }

    public static requireNotSameStringAs(compareArray, keyOverride?:string):ValidationFunction<any>{
        return (key:string,attribute:any,obj:any)=>{ 
            return compareArray.includes(attribute) ? 
            {success: false, error: `${keyOverride ? keyOverride : key} should be unique`} : 
            {success:true, error:``}
        }
    }

    public static requireHasTwoDecimalPlaces(keyOverride?:string):ValidationFunction<any>{
        const twoDecimalPlaces = new RegExp(/\.[0-9]{2}?/)
        return (key:string, attribute:any, obj:any) =>{
            return twoDecimalPlaces.test(attribute) ? 
                { success: true, error: ``} : 
                { success:false, error: `${keyOverride ? keyOverride : key} should contain two decimal places.`}
        };
    }

    public static requireIsPositiveNumber(keyOverride?:string):ValidationFunction<any>{
        const positiveNumber = new RegExp(/^[1-9]\d*?/);
        const isNegative = new RegExp(/^-/);
        return (key:string, attribute:any, obj:any) =>{
            return attribute === '0' ? 
                {success: false, error: `${keyOverride ? keyOverride : key} cannot be zero`} : 
                isNegative.test(attribute) ? 
                { success: false, error: `${keyOverride ? keyOverride : key} cannot be negative`} : 
                positiveNumber.test(attribute) ? 
                { success: true, error: ``} : 
                { success:false, error: `${keyOverride ? keyOverride : key} should start with 1-9`}
        };
    }

    public static requirePositveNumberWithTwoDecimalPlaces(keyOverride?:string):ComposedValidationEntry<any>{
        return new ComposedValidationEntry<any>(
            new ValidationEntry(Validators.requireIsPositiveNumber(keyOverride)), new ValidationEntry(Validators.requireHasTwoDecimalPlaces(keyOverride)))
    }

    public static requireRange(startingNum:number, endingNum:number, keyOverride?:string):ValidationFunction<any>{
        return (key:string, attribute:number, obj:any) =>{
            return attribute < startingNum || attribute > endingNum ? {success:false, error:`${keyOverride ? keyOverride : key} must be between ${startingNum} and ${endingNum}.`} : {success:true, error:''};
        };
    }

    public static requireBlankValidator(keyOverride?:string, passedSelect?:string):ValidationFunction<any>{
        return (key:string, attribute:string, obj:any) =>{
            return attribute ? {success:false, error:`${keyOverride ? keyOverride : key} cannot have value if ${passedSelect} is not Yes.`} : {success:true, error:''};
        };
    }

    public static requireNonNullValidator(keyOverride?:string):ValidationFunction<any>{
        return (key:string, attribute:any, obj:any) =>{
            return !attribute ? {success:false, error:`${keyOverride ? keyOverride : key} cannot be empty.`} : {success:true, error:''};
        };
    }

    public static requireNotFutureDate(keyOverride?:string):ValidationFunction<any>{
        return (key:string, attribute:Date, obj:any) =>{
            return new Date(attribute) > new Date() ? {success:false, error:`${keyOverride ? keyOverride : key} cannot be in the future.`} : {success:true, error:''};
        };
    }

    public static requireNotBeforeToday(keyOverride?:string):ValidationFunction<any>{
        return (key:string, attribute:Date, obj:any) =>{
            return new Date(attribute) < new Date() ? {success:false, error:`${keyOverride ? keyOverride : key} cannot be in the past.`} : {success:true, error:''};
        };
    }

    public static requireValidEmail(keyOverride?:string):ValidationFunction<any>{
        return (key:string, attribute:string, obj:any) =>{
            return !attribute || !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(attribute) ? { success:false, error: `${keyOverride ? keyOverride : key} must be a valid email address.` } : { success:true, error:'' };
        };
    }

    public static requireValidSSN(keyOverride?:string):ValidationFunction<any>{
        return (key:string, attribute:string, obj:any) =>{
            return !attribute || !/^[0-9]{3}\-?[0-9]{2}\-?[0-9]{4}$/.test(attribute) ? {success:false, error:`${keyOverride ? keyOverride : key} is not valid`} : {success:true, error:''};
        };
    }

    public static requireLength(maxLength:number, keyOverride?:string):ValidationFunction<any>{
        return (key:string, attribute:string, obj:any) =>{
            return attribute.length > maxLength ? {success:false, error:`${keyOverride ? keyOverride : key} cannot be longer than ${maxLength.toString()}.`} : {success:true, error:''};
        };
    }

    public static requirePhone(keyOverride?:string):ValidationFunction<any>{
        return (key:string, attribute:string, obj:any) =>{
            return !isPossiblePhoneNumber(attribute) ? {success:false, error:`${keyOverride ? keyOverride : key} is not a valid phone number.`} : {success:true, error:''};
        };
    }

    public static requireZip(keyOverride?:string):ValidationFunction<any>{
        return (key:string, attribute:string, obj:any) =>{
            return !/^\d{5}(-\d{4})?(?!-)$/.test(attribute) ? {success:false, error:`${keyOverride ? keyOverride : key} is not a valid zipcode.`} : {success:true, error:''};
        };
    }

    public static requireNotBlankIfExistsValidator(initialValue:any, keyOverride?:string):ValidationFunction<any>{
        return (key:string, attribute:any, obj:any) =>{
            return initialValue && !attribute ? {success:false, error:`${keyOverride ? keyOverride : key} can not be changed to blank.`} : {success:true, error:''};
        };
    }

    public static requireNotBeforePatientDOB(patientDOB:Date, keyOverride?:string):ValidationFunction<any>{
        return (key:string, attribute:Date, obj:any) =>{
            return new Date(attribute).getTime() < new Date(patientDOB).getTime() ? {success:false, error:`${keyOverride ? keyOverride : key} can not be before Patient DOB.`} : {success:true, error:''};
        };
    }

    public static requireDOBLessThan150Years(years:number, keyOverride?:string):ValidationFunction<any>{
        return (key:string, attribute:Date, obj:any) =>{
            return calculateAge(attribute) > years ? {success:false, error:`${keyOverride ? keyOverride : key} can not be more than ${years} years ago.`} : {success:true, error:''};
        };
    }

    public static requireDOBLessThan20Years(keyOverride?:string):ValidationFunction<any>{
        return (key:string, attribute:Date, obj:any) =>{
            return calculateAge(attribute) > 20 ? {success:false, error:`${keyOverride ? keyOverride : key} can not be more than 20 years old. Exemptions are only for children in Child Care, Pre-K, K-12th grade or Head Start.`} : {success:true, error:''};
        };
    }

    public static requireNoAmpersand(keyOverride?:string):ValidationFunction<any>{
        return (key:string, attribute:string, obj:any) =>{
            return attribute.includes('&') ? {success:false, error:`${keyOverride ? keyOverride : key} cannot include the & symbol, if needed, please use the word 'And'`} : {success:true, error:''};
        };
    }

    public static requireNotBlankAndLengthAndUnique(maxLength:number, compareArray, keyOverride?:string):ComposedValidationEntry<any>{
        return new ComposedValidationEntry<any>(
            new ValidationEntry(Validators.requireNotBlankValidator(keyOverride)), 
            new ValidationEntry(Validators.requireLength(maxLength, keyOverride)),
            new ValidationEntry(Validators.requireNotSameStringAs(compareArray, keyOverride))
        )
    }

    public static requireNotBlankAndLength(maxLength:number, keyOverride?:string):ComposedValidationEntry<any>{
        return new ComposedValidationEntry<any>(
            new ValidationEntry(Validators.requireNotBlankValidator(keyOverride)), new ValidationEntry(Validators.requireLength(maxLength, keyOverride)), new ValidationEntry(Validators.requireNoAmpersand(keyOverride)))
    }

    public static requireNotBlankAndSSN(keyOverride?:string):ComposedValidationEntry<any>{
        return new ComposedValidationEntry<any>(
            new ValidationEntry(Validators.requireNotBlankValidator(keyOverride)), new ValidationEntry(Validators.requireValidSSN(keyOverride)))
    }

    public static requireDOB(years:number, keyOverride?:string):ComposedValidationEntry<any>{
        return new ComposedValidationEntry<any>(
            new ValidationEntry(Validators.requireNonNullValidator(keyOverride)), new ValidationEntry(Validators.requireNotFutureDate(keyOverride)), new ValidationEntry(Validators.requireDOBLessThan150Years(years, keyOverride)))
    }

    //Validation for OSDH to ensure a patient is of school age
    public static requireSchoolAgeDOB(keyOverride?:string):ComposedValidationEntry<any>{
        return new ComposedValidationEntry<any>(
            new ValidationEntry(Validators.requireNonNullValidator(keyOverride)), new ValidationEntry(Validators.requireNotFutureDate(keyOverride)), new ValidationEntry(Validators.requireDOBLessThan20Years(keyOverride)))
    }

    public static requireFactor(startingNum, endingNum, keyOverride?:string):ComposedValidationEntry<any>{
        return new ComposedValidationEntry<any>(
            new ValidationEntry(Validators.requireNonNullValidator(keyOverride)), new ValidationEntry(Validators.requireRange(startingNum, endingNum, keyOverride)), new ValidationEntry(Validators.requireIsInt(keyOverride)))
    }

   

}