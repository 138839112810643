import { TransactionListResponse, TransactionDetailsResponse } from "../types/Transaction";
import NetworkUtil from "./NetworkUtil";

export default class PaymentAPI {
    public static async getPaymentPricing():Promise<{pricing:any, success:boolean, reason:string}>{
        return NetworkUtil.makeGet("/api/admin/payment/getPaymentPricing");
    }

    public static async getCustomerProfile(ANETCustomerProfileID, ANETCustomerPaymentProfileID):Promise<{success:boolean,reason:string, customerProfile?}>{
        return NetworkUtil.makePost("/api/admin/payment/getCustomerProfile", {
            customerProfileID: ANETCustomerProfileID, 
            customerPaymentProfileID: ANETCustomerPaymentProfileID
        })
    }

    public static async createCustomerProfile(ANETCustomerProfile, description, paymentNounce):Promise<{success:boolean,reason:string, createdCustomerProfile?}>{
        return NetworkUtil.makePost("/api/admin/payment/createCustomerProfile", {
            ANETCustomerProfile: ANETCustomerProfile, 
            description: description, 
            paymentNounce: paymentNounce
        })
    }

    public static async updateCustomerProfile(ANETMerchantID, ANETCustomerProfileID, ANETCustomerPaymentProfileID, ANETCustomerProfile, description, paymentNounce):Promise<{success:boolean,reason:string, updatedCustomerProfile?}>{
        return NetworkUtil.makePost("/api/admin/payment/updateCustomerProfile", {
            merchantID: ANETMerchantID, 
            customerProfileID: ANETCustomerProfileID, 
            customerPaymentProfileID: ANETCustomerPaymentProfileID, 
            ANETCustomerProfile: ANETCustomerProfile, 
            description: description, 
            paymentNounce: paymentNounce
        })
    }

    public static async updateCustomerProfileWithoutCardInfo(ANETMerchantID, ANETCustomerProfileID, ANETCustomerPaymentProfileID, ANETCustomerProfile):Promise<{success:boolean,reason:string, updatedCustomerProfile?}>{
        return NetworkUtil.makePost("/api/admin/payment/updateCustomerProfileWithoutPaymentInfo", {
            merchantID: ANETMerchantID, 
            customerProfileID: ANETCustomerProfileID, 
            customerPaymentProfileID: ANETCustomerPaymentProfileID, 
            ANETCustomerProfile: ANETCustomerProfile
        })
    }

    public static async getTransactionListForANetCustomerID(ANETMerchantID, ANETCustomerProfileID, ANETCustomerPaymentProfileID, ANETCustomerProfile):Promise<{success:boolean,reason:string,data:TransactionListResponse}>{
        return NetworkUtil.makePost("/api/admin/payment/getTransactionListForCustomer",{
            merchantId:ANETMerchantID,
            customerProfileID:ANETCustomerProfileID,
            customerPaymentProfileID: ANETCustomerPaymentProfileID, 
            ANETCustomerProfile: ANETCustomerProfile
        })
    }

    public static async filterTransactions(filter:{SelectedFacility,TransID},page):Promise<{success:boolean,reason:string, data:TransactionListResponse}>{
        return NetworkUtil.makePost("/api/admin/payment/filterTransactions?page="+page,filter)
    }


    public static async queryTransaction(filter:{TransID}):Promise<{success:boolean,reason:string, data:TransactionDetailsResponse}>{
        return NetworkUtil.makePost("/api/admin/payment/queryTransaction",filter)
    }
}