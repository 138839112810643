import React from "react";
import Overlay from "../Overlay";
import AdminAPI from "../../network/AdminAPI";
import { UserContext } from "../../types/UserContext";
import { getIdToken, signOut } from "firebase/auth";
import { auth } from "../../FirebaseConfig";
import { sweetalert } from "../../App";
import { AuthContext } from "../../context/AuthContext";


interface AdminHomePageState {
  showLoading?: boolean;
  facilitySamplesArray
  facilities
  batches
  user?:UserContext
  totalPendingExemptions
  haveAdminData:boolean
}

export default class AdminHomePage extends React.Component<
  any,
  AdminHomePageState
> {
  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
      facilitySamplesArray: [],
      facilities: [],
      batches: [],
      totalPendingExemptions: 0,
      haveAdminData: false
    };
  }

  componentDidMount(): void {
    document.title = 'Home | Oklahoma State Department of Health';
    document.body.style.backgroundImage = ``;
    document.body.style.backgroundColor = '#FFFFFF'
    this.setState({ showLoading: true }, async () => {
      AdminAPI.getTotalPendingExemptions().then(data => {
        if (data.success) {
          this.setState({ totalPendingExemptions: data.data[0]['count(*)'], haveAdminData: true})
        // } else {
        //   // Likely a Physician so let's send 'em to /admin/review
        //   window['location'] = '/admin/review' as unknown as Location
        }
      })
      // this.setState({showLoading: false})
      await this.checkUserRole()
    });
  }

  checkUserRole = async() => {
    let idToken = await getIdToken(this.context, true);
    const userCredential = await AdminAPI.getAuthenticated({ token: idToken });

    if( userCredential && userCredential.user &&
        userCredential.user.UserRoles && 
        userCredential.user.UserRoles.includes('Public')
    ){
      sweetalert.fire({
        icon: 'info',
        text: 'User Role is Public.  Please sign into our public portal.',
      }).then((confirm) => {
          return signOut(auth).then(() => {
            // redirect user to the public portal
            const loginEnv = process.env.REACT_APP_ENV_FOR_LOGIN;
            let publicPortalURL = `imm-public.osdh.immytech.com`;
            let domain = loginEnv === 'production' ? `https://${publicPortalURL}` : `https://${loginEnv}.${publicPortalURL}`;

            window['location'] = domain + '/login' as unknown as Location;
          });
      });
    }
    else if( userCredential && userCredential.user &&
        userCredential.user.UserRoles && 
        userCredential.user.UserRoles.includes('Physician')
    ){
            window['location'] = '/admin/review' as unknown as Location;
    }
 
    this.setState({showLoading: false})
  }


  getCard(title, number){
    return (
      <div className="col-12 col-md-6 col-xl-3">
        <div className="card mt-2 text-center font-weight-bold">
          <div className="card-header verlag-bold">
            <h4>{title}</h4>
          </div>
          <div className="card-body" style={{fontSize: '3rem'}}>
            {number || number === 0 ? number : 'N/A'}
          </div>
        </div>
      </div>
  )}

  //total number with TestOrderedDate present but TestResultDate null
  getAwaitingResult(array){
    return array.filter(f => f.TestOrderedDate && !f.TestResultDate).length;
  }

  // total number with HasPhysicianReviewed in Samples table = 0 and and ResultApprovedDate in Batches table is present 
  getAwaitingReview(array){
    let numberAwaitingReview = 0;
    let notReviewedSamples = array.filter(f => !f.HasPhysicianReviewed);
    if(notReviewedSamples && notReviewedSamples.length > 0){
      notReviewedSamples.map(s => {
        if(s.BatchID && this.state.batches && this.state.batches.length > 0 && this.state.batches.find(f => f.ID === s.BatchID).ResultApprovedDate){
          numberAwaitingReview ++
        }
      })
    }
    return numberAwaitingReview;
  }

  //total number with Reported Date
  getCompleted(array){
    return array.filter(f => f.HasPhysicianReviewed && f.TestResultDate).length;
  }
      

  render():
    | React.ReactElement
    | string
    | number
    | {}
    | React.ReactNodeArray
    | React.ReactPortal
    | boolean
    | null
    | undefined {

      // console.log('AdminHomePage state', this.state)

    return (  
      this.state.haveAdminData && 
      <React.Fragment>
        <Overlay show_loading={this.state.showLoading} />
        <main id="main-content" tabIndex={-1} aria-label="Home Page Samples Statistics">
            <div className={this.state.showLoading ? "d-none" : "container-fluid"}>
              <div className={"row"}>
                <div className="col-12 pt-2">
                    <article>
                    <div className="row">
                      <div className="col-12 col-md-6 col-xl-3">
                        <div className="card mt-2 text-center font-weight-bold landingBtn landingTotalExemptions">
                          <div className="card-header verlag-bold">
                            <h4>Total Pending Exemptions</h4>
                          </div>
                          <div className="card-body d-flex justify-content-center align-items-center w-100 h-100 p-0" style={{fontSize: '3rem'}}>
                            {this.state.totalPendingExemptions}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-md-6 col-xl-3">
                            <a role="button" className=" card mt-2 text-center font-weight-bold d-flex flex-column justify-content-center align-items-center col-12 btn landingBtn immySubmitButtonSolid p-0" href={`/admin/ExemptionRecords`} > 
                                <span className='pr-2 flex-grow-1 d-flex justify-content-center align-items-center'>
                                    <img src={"https://storage.googleapis.com/streamline-bucket/viewRecordEnhanced.svg"} alt="" className='landingIcon'/>
                                </span>
                                <span className="w-100 py-1" style={{backgroundColor: "rgba(0,0,0,0.2)"}}>
                                    View Vaccine Exemption Records
                                </span>
                            </a>
                      </div>
                    </div>
                    </article>
                    
                  
                    
                  
                </div>
              </div>
          </div>
        </main>
      </React.Fragment>
      
    );
  }
}
