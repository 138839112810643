import React from "react";
import Overlay from "../Overlay";
import SimpleTable from "../tables/SimpleTable";
import FilterCard, { FIELD_TYPE, ReactSelect } from "../FilterCard";
import {
  getNumPages,
  getPageOptions,
  hideModal,
  parseDate,
  showModalNoOutsideClick,
  slicePages,
} from "../../util/FormatUtil";
import { sweetalert } from "../../App";
import Select from "react-select";
import NetworkUtil from "../../network/NetworkUtil";
import { Column } from "../tables/TableBase";
import { Sorter, TableOrder } from "../../sorting/Sorter";
import ConnectAPI from "../../network/ConnectAPI";
import PaginationTool from "../PaginationTool";
import SystemAPI from "../../network/SystemAPI";
import moment from "moment";
import DatePicker from "react-datepicker";
import HL7PreviewModal from '../modals/HL7PreviewModal';
import ExemptionRecordModal from "../modals/ExemptionRecordModal";




const ITEMS_PER_PAGE = 25;

interface HL7MessagesManagementState {
    showLoading:boolean
    selectedMessage
    messageQueryResults
    tableData:{}[]
    filter?:{
        ReqNum,
        FacilityIDs,
        Method
        BeginSentDate,
        EndSentDate,
        BeginReceivedDate,
        EndReceivedDate,
    }
    direction?:TableOrder
    page_options:ReactSelect[]
    selected_page?:{label,value}
    allMessages
    states: any;
    countries: any;
    genders: any;
    ethnicities: any;
    races: any;
    immunizations?
    selectedExemption
}

export class HL7MessagesManagement extends React.Component<
  any,
  HL7MessagesManagementState
> {
  constructor(props) {
    super(props);

        this.state = {
            showLoading: false,
            selectedMessage: {},
            selectedExemption: null,
            tableData: [] as any,
            messageQueryResults: [] as any,
            filter: {
                ReqNum:null,
                FacilityIDs: [],
                Method: null,
                BeginSentDate: null,
                EndSentDate: null,
                BeginReceivedDate: null,
                EndReceivedDate: null,
            },
            direction: 'asc',
            page_options: [{label: "1", value: "1"}],
            selected_page: {label: "1", value: 1},
            allMessages: [],
            states: [],
            countries: [],
            genders: [],
            ethnicities: [],
            races: [],
    };

    // this.createOrModifyService = this.createOrModifyService.bind(this);
  }

  componentDidMount() {
    document.title = 'HL7 Messages Management | Oklahoma State Department of Health';
    ConnectAPI.getAllHL7Messages().then(data => {
        //@ts-ignore
        this.setState({allMessages: data.hl7Messages})
    })
    SystemAPI.getAllStates().then((data) => {
        this.setState({ states: data });
    });
    SystemAPI.getAllCountries().then((data) => {
        this.setState({ countries: data });
    });
    SystemAPI.getAllRaces().then((data) => {
        this.setState({ races: data });
    });
    SystemAPI.getAllGenders().then((data) => {
        this.setState({ genders: data });
    });
    SystemAPI.getAllEthnicities().then((data) => {
        this.setState({ ethnicities: data });
    });
    SystemAPI.getAllImmunizations().then(data => {
      this.setState({immunizations: data})
    })
        // this.queryHL7MessageData(1);  
  }


  queryHL7MessageData(page:number){
    if(this.state.filter.BeginSentDate || this.state.filter.EndSentDate){
      let valid = true;
      valid = this.dateCheck(this.state.filter.BeginSentDate, this.state.filter.EndSentDate, "Begin Sent Date", "End Sent Date", "Sent Date")
      if(!valid){
        return
      }
    }
    if(this.state.filter.BeginReceivedDate || this.state.filter.EndReceivedDate){
      let valid = true;
      valid = this.dateCheck(this.state.filter.BeginReceivedDate, this.state.filter.EndReceivedDate, "Begin Received Date", "End Received Date", "Received Date")
      if(!valid){
        return
      }
    }
    this.setState({showLoading: true, tableData: []}, async () => {
        let results = await ConnectAPI.filterHL7Message({
            filter: this.state.filter
        });


        if(results.data.length < 1){
            this.setState({showLoading: false, tableData: [], messageQueryResults: null})
            return sweetalert.fire({title: '', html: "<p aria-live='polite'>No messages returned</p>", icon: 'info'})
        }
        if(!results.success){
            this.setState({showLoading: false})
            return sweetalert.fire({title: '', html: "<p aria-live='polite'>Unable to filter messages at this time</p>", icon: 'error'})

        }

        this.setState({
            tableData: slicePages(results.data, page, ITEMS_PER_PAGE),
            messageQueryResults: results.data,
            page_options: getPageOptions(getNumPages(results.data, ITEMS_PER_PAGE)),
            showLoading: false,
        });
    });
  }


  handleExportToCSV() {
    this.setState({ showLoading: true }, async () => {
      await NetworkUtil.downloadCSV(
        "/api/admin/connect/csv",
        "HL7 Messages.xlsx",
        { filter: this.state.filter }      );
      this.setState({ showLoading: false });
    });
  }

  changePage(page: number) {
    let allMessages = this.state.messageQueryResults;
    let returnData = slicePages(allMessages, page, ITEMS_PER_PAGE);
    this.setState({ tableData: returnData });
  }

  renderMessagesFilterFields(){
    return (
        <FilterCard fields={[
            {
                label: "ReqNum",
                "key": "ReqNum",
                type: FIELD_TYPE.TEXT,
                isFilter: true
            },
            
        ]} filterChanged={(e)=> {
          this.setState((prevState) => ({
            filter: {
              ...prevState.filter,
              ...e
            }
          }))
        }}
        />
    )
  }

  useSorter(col:Column){
    let sorter = new Sorter<any>()

    this.setState({tableData: sorter.sortByKey(this.state.tableData, col.key as keyof any,this.state.direction), direction: this.state.direction === 'asc' ? 'desc' : 'asc'});
  }

  downloadHL7File(reqNum){
    this.setState({ showLoading: true })
    ConnectAPI.getHL7FileFromCloud(reqNum)
      .then(blobUrl => {
            if(typeof blobUrl === 'string'){
                const a = document.createElement('a');
                a.href = blobUrl;
                a.download = `${reqNum}.hl7`;
                document.body.appendChild(a);
                a.click();
                a.remove();
                URL.revokeObjectURL(blobUrl);
            }
            else{
                console.error('Error from server:', blobUrl);
                return sweetalert.fire({icon: 'error', title: '', html: "<p aria-live='polite'>Error when downloading hl7 file. Please try again.</p>"})
            }
    })
    .catch(error => {
        console.error('Error downloading the file:', error);
        return sweetalert.fire({icon: 'error', title: '', html: "<p aria-live='polite'>Error when downloading hl7 file. Please try again.</p>"})
    })
    .finally(() => {
        this.setState({showLoading: false});
    });
  }


  handleDateOnChange(value, state){
    if (value) {
        this.setState((prevState) => ({
          filter: {
            ...prevState.filter,
            [state]: value
          }
        }))
    } else {
        this.setState((prevState) => ({
          filter: {
            ...prevState.filter,
            [state]: null
          }
        }))
    }
}


  getDateRange(beginStateToSet, beginState, endStateToSet, endState, label, tooltip){
    return (
        <div className="form-group row">
            <div className={"col-12 col-sm-4"} data-toggle={"tooltip"} data-placement={"top"} title={tooltip} >
                <label htmlFor={label}  className={"col-form-label"} > {label} </label>
            </div>
            <div className={"col-12 col-sm-8 pl-0 pr-1"}>
                <div className="row">
                    <div className={"col-4"}>
                        <label id={`begin${label.replaceAll(' ', '')}Filter`} aria-label={`${label} Begin Search`} htmlFor={label}>Begin: </label>
                    </div>
                    <div className={"col-8 text-lg-right"} id={label} >
                        <DatePicker ariaLabelledBy={`begin${label.replaceAll(' ', '')}Filter`} name={"beginDate"} minDate={new Date("01-01-1000")} maxDate={new Date("12-31-9999")} placeholderText="--/--/----" selected={ beginState ? Date.parse(moment(beginState, "MM-DD-YYYY").toISOString()) : null } onChange={(e) => this.handleDateOnChange(e, beginStateToSet) } />
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-4"}>
                        <label id={`end${label.replaceAll(' ', '')}Filter`} aria-label={`${label} End Search`} htmlFor='endDate'>End: </label>
                    </div>
                    <div className={"col-8 text-lg-right"} id={label}>
                        <DatePicker ariaLabelledBy={`end${label.replaceAll(' ', '')}Filter`} name={"endDate"} minDate={new Date("01-01-1000")} maxDate={new Date("12-31-9999")} placeholderText="--/--/----" selected={ endStateToSet ? Date.parse(moment(endState, "MM-DD-YYYY").toISOString()) : null } onChange={(e) => this.handleDateOnChange(e, endStateToSet) } />
                    </div>
                </div>
            </div>
        </div>
    );
  }


  dateCheck(beginDateState, endDateState, beginLabel, endLabel, label){
    let valid = true
    if ((beginDateState && !endDateState) || (!beginDateState && endDateState)) {
      sweetalert.fire({
        icon: "error",
        title: "",
        html: `<p aria-live='polite'>Please enter a valid ${beginLabel} and an ${endLabel}</p>`,
      });
      valid = false;
    }

    if (beginDateState > endDateState) {
      sweetalert.fire({
        icon: "error",
        title: "",
        html: `<p aria-live='polite'>${beginLabel} cannot be after ${endLabel}</p>`,
      });
      valid = false;
    }

    if (beginDateState > new Date() || endDateState > new Date()) {
      sweetalert.fire({
        icon: "error",
        title: "",
        html: `<p aria-live='polite'>Cannot search for ${label} in the future</p>`,
      });
      valid = false;
    }

    if (beginDateState > new Date() || endDateState > new Date()) {
      sweetalert.fire({
        icon: "error",
        title: "",
        html: `<p aria-live='polite'>Cannot search for ${label} in the future</p>`,
      });
      valid = false;
    }
    return valid
  }

  preview(message){
    this.setState({selectedMessage: message})
    showModalNoOutsideClick(HL7PreviewModal.ID)
  }

  getSelectedExemption(reqNum){
    this.setState({ showLoading: true }, () => {
        ConnectAPI.getHL7Exemption(reqNum).then(data => {
            this.setState({selectedExemption:JSON.parse(JSON.stringify(data.data))}, () => showModalNoOutsideClick(ExemptionRecordModal.ID))
        })
        .catch(error => {
            console.error('Error retrieving exemption:', error);
            return sweetalert.fire({icon: 'error', title: '', html: "<p aria-live='polite'>Error retrieving exemption. Please try again.</p>"})
        })
        .finally(() => {
            this.setState({showLoading: false});
        });
    })   
  }

  render() {
    return (
      <React.Fragment>
        <PaginationTool />
          <Overlay show_loading={this.state.showLoading} />
          <HL7PreviewModal selectedMessage={this.state.selectedMessage}/>
          <ExemptionRecordModal
            selectedExemption={this.state.selectedExemption}
            immunizations={this.state.immunizations}
            countries={this.state.countries}
            states={this.state.states}
            genders={this.state.genders}
            races={this.state.races}
            ethnicities={this.state.ethnicities}
            onSubmit={() => {hideModal(ExemptionRecordModal.ID)}}
          />
          <div className="container-fluid ">
              <div className={"row"}>
                  <div className="col-12 col-md-12 col-lg-8 col-xl-5 pt-2">
                      <main id="main-content" tabIndex={-1} aria-label="HL7 Messages Management">
                          <div className="card mb-2">
                              <div className="card-header verlag-bold">
                                  <h4>HL7 Messages Management</h4>
                              </div>
                              <div className="card-body">
                                  {this.renderMessagesFilterFields()}
                                  {this.getDateRange('BeginSentDate', this.state.filter.BeginSentDate, 'EndSentDate', this.state.filter.EndSentDate, 'Sent Date', 'Date HL7 Message was sent')}
                              </div>
                              <div className="card-footer">
                                  <button className={"btn immySubmitButtonOutline"}
                                          onClick={ () => {
                                              this.setState({
                                                  selected_page: {label: 1, value: 1}}, () => {
                                                  this.queryHL7MessageData(this.state.selected_page.value)
                                              })
                                          }}
                                  >Search</button>
                              </div>
                          </div>
                      </main>
                  </div>

                  <div className="col-md-12 pt-2">
                  {
                      this.state.tableData &&
                      this.state.tableData.length > 0 &&

                          <div className="card mt-2" style={{marginBottom: '4rem'}}>
                              <div className="card-header verlag-bold">
                                  <h4 className="text-center text-md-left">Messages
                                    <section className="tableHeaderSection float-md-right d-flex justify-content-around">
                                        <h4 className={'float-md-right'} aria-label="Total Records" role="alert">Total: {this.state.messageQueryResults.length}</h4>
                                        <h4 className="float-right align-middle pr-2 ml-5">Page </h4>
                                        <div className="align-middle float-right pages ">
                                          <Select
                                              isSearchable={true}
                                              placeholder={"1"}
                                              noOptionsMessage={() => "No option"}
                                              value={this.state.selected_page}
                                              aria-label="Table Page Number"
                                              onChange={(e: ReactSelect) => this.setState({selected_page: e},
                                                  () => this.changePage(e.value))}
                                              className={"state_select"}
                                              options={this.state.page_options}
                                          />
                                        </div>
                                    </section>
                                    <button className={"d-none d-md-inline btn btn-outline-primary ml-3"}
                                            onClick={() => this.handleExportToCSV()}
                                    >Export to CSV
                                    </button>
                                  </h4>
                              </div>
                              <div className="card-body p-0 m-0 table-responsive">
                                  <SimpleTable table_style='tableFixHead' columns={[
                                      {
                                          label: "Conf #", key: "ReqNum",
                                          rawFormat: (val) => {
                                              return <a href={"#"} className={'tableNameLinkColor'} onClick={() => {
                                                this.getSelectedExemption(val.ReqNum)
                                              }}>{val.ReqNum}</a>
                                          },
                                          popoverText: "Click to sort by ReqNum"
                                      },
                                      {
                                        label: "Method", key: "Method",
                                        popoverText: "Click to sort by Method",
                                      },
                                      {
                                          label: "Sent Date", key: "SentDate",
                                          popoverText: "Click to sort by Sent Date",
                                          rawFormat: (val) => {
                                              return <p>{parseDate(val.SentDate)}</p>
                                          }
                                      },
                                      {
                                          label: "Received Date", key: "ReceivedDate",
                                          popoverText: "Click to sort by Received Date",
                                          rawFormat: (val) => {
                                              return <p>{parseDate(val.ReceivedDate)}</p>
                                          }
                                      },
                                      {label:"Preview", key:"",
                                        rawFormat: (val) => (
                                            <button 
                                                type={'button'} 
                                                className={'btn btn-outline-primary'} 
                                                onClick={() => this.preview(val)}>Preview
                                            </button> )
                                      },
                                      {label:"HL7 File", key:"",
                                        rawFormat: (val) => (
                                            <button 
                                                type={'button'} 
                                                className={'btn btn-outline-primary'} 
                                                onClick={() => this.downloadHL7File(val.ReqNum)}>Download
                                            </button> )
                                        }
                                  ]} table_data={this.state.tableData} columnClickedCallback={(col =>{
                                      this.useSorter(col);
                                  })} />
                              </div>
                          </div>
                  }
                  </div>
              </div>
          </div>
      </React.Fragment>
  )
  }
}