import LoadingOverlay from 'react-loading-overlay';
import styled from 'styled-components'
import React from "react"
const StyledLoader = styled(LoadingOverlay)`
    position:fixed !important;
    display: ${props => props.showLoader ? "initial" : "none"};
    z-index:${props => props.zIndex};
    width:100vw;
    height:100vh;
    min-height:100vh;
    min-width:100vw;
    top:0;
    left:0;
`;

interface InBetweenOverlayProps{
    showInBetween:boolean
    zIndex?:number
}
export default class InBetweenOverlay extends React.Component<InBetweenOverlayProps, any>{
    render() {
        return (<StyledLoader
            active={this.props.showInBetween}
            spinner={false}
            text={''}
            zIndex={this.props.zIndex ? this.props.zIndex : 100000}
            classNamePrefix='MyLoader_'
            className={""}
            showLoader={this.props.showInBetween}/>)
    }
}