import React, {useEffect, useContext} from 'react';
import './App.css';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
// import ResultsForm from "./components/ResultsForm";
// import PatientForm from "./components/PatientForm";
import AdminPanel from "./components/admin/AdminPanel";
// import Confirmation from "./components/Confirmation";
// import PrivatePod from "./components/PrivatePod";
import Login from "./components/admin/Login"; //FC
// import PatientCheckIn from "./components/PatientCheckIn";
// import {RetrieveConfirmation} from "./components/RetrieveConfirmation";
// import {TestManagement} from "./components/admin/TestManagement";
// import Alert from "./../src/components/admin/Alert";
// import CheckIn from "./components/admin/CheckIn";
// import SamplesReport from "./components/admin/SamplesReport";
// import WalkinForm from "./components/admin/WalkinForm";
import {Facility} from "./components/admin/Facility";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import LoggedOut from "./components/admin/LoggedOut";
// import RapidTestManagement from "./components/admin/RapidTestManagement";
// // import PodStats from "./components/admin/PodStats";
// import Schedules from "./components/admin/Schedules";
import {AuditLogs} from "./components/admin/AuditLogs";
import {UserManagement} from "./components/admin/UserManagement";
import {ServicesManagement} from "./components/admin/ServicesManagement";
import {ResultsManagement} from "./components/admin/ResultsManagement";
import {CommunicationTemplates} from "./components/admin/CommunicationTemplates";
import AdminHomePage from "./components/admin/AdminHomePage";
import {CustomFields} from "./components/admin/CustomFields";
import { ReportSettings } from './components/admin/ReportSettings';
import { AuthContext } from './context/AuthContext';
import ForgotPassword from './components/admin/ForgotPassword';
import Home from './components/Home';
import SystemAPI from './network/SystemAPI';
import { PatientManagement } from './components/admin/PatientManagement';
import { PasswordSettings } from './components/admin/PasswordSettings';
import { BannerManagement } from './components/admin/BannerManagement';
// import { FakeSampleGenerator } from './components/admin/FakeSampleGenerator';
// import { PricingManagement } from './components/admin/PricingManagement';
// import { DiscountManagement } from './components/admin/DiscountManagement';
import { DocumentUpload } from './components/admin/DocumentUpload';
import SkipLink from './components/SkipLink';
import { PatientFields } from './components/admin/PatientFields';
// import { Transactions } from './components/admin/Transactions';
import ExemptionRecords from './components/admin/ExemptionRecords';
import { HL7MessagesManagement } from './components/admin/HL7MessagesManagement';
import Approvals from './components/admin/Approvals';
import "react-datepicker/dist/react-datepicker.css";
import { PhysicianHomePage } from './components/admin/PhysicianHomePage';

const swal = withReactContent(Swal);

export const CAPTCHA = "6Lc4JUAcAAAAAGPcDlen-26x_KpyZUjY7acuP4Al";

//setting defaults for sweetalert
export let sweetalert = swal.mixin({
    showCloseButton:false, // hide the 'x' at the top of the modal
    confirmButtonColor: '#21212E',      // dark blue
    cancelButtonText: 'Cancel',
    cancelButtonColor: '#808080',       // grey 
    denyButtonColor: '#dc3741',         // standard red
    allowOutsideClick: true,           // enforce user interact with modal buttons
    allowEscapeKey: false,              // enforce user interact with modal buttons
    reverseButtons: true,   // cancel left, confirm right
    // stopKeydownPropagation:false,
    keydownListenerCapture:false,
    // allowEnterKey:true,      
    showClass: {
        backdrop: 'swal2-noanimation',  // disable backdrop animation
        popup: '',                      // disable popup animation
        icon: '',                       // disable icon animation
    },
    hideClass: {
        popup: '',                     // disable popup fade-out animation
    },
    customClass: {
        container: 'swal-on-top swal2-html-container'
    }
});

function App(){
    const userContext = useContext(AuthContext);

    useEffect(()=>{
        loadScript(`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_PLACES_API_KEY}&libraries=places&loading=async`);
        let currentURL = window.location.href;
        let title;
        const getProductBranding = async () => {
            let data = await SystemAPI.getProductBrandingFromURL(currentURL)
            title = data.Name;
            document.title = title
        }

        getProductBranding()

    },[]);// runs on load

    const loadScript = (src:string) => {
        var tag = document.createElement('script');     
        tag.src = src;
        document.body.appendChild(tag);
    }

    const getNavRoute = (comp:any)=>{
        return(
            <React.Fragment>
                <Nav />
                {comp}
                {/* <Footer/> */}
            </React.Fragment>
        )
    }

    const getAdminNavRoute = (comp:any, props)=>{
        document.body.style.backgroundColor = 'white'
        return (
            <> 
            { userContext && 
                <React.Fragment>
                    
                    <SkipLink targetId="main-content" label="Skip to Main Content" />
                    <AdminPanel {...props} />
                    {comp}
                </React.Fragment>
            }
            </>
        )
    }

    const getLoginRoute = (comp:any)=>{
        return (
            <React.Fragment>
                {comp}
            </React.Fragment>
        )
    }
    return (
        <BrowserRouter>
            <React.Fragment>
                <Switch>
                    <Route path="/" render={(props) => getNavRoute(<Home />)} exact />
                    {/* <Route path="/private" render={(props) => getNavRoute(<PrivatePod />)} exact />
                    <Route path="/appointment" render={(props) => getNavRoute(<PatientForm />)} exact /> */}
                    {/* <Route path="/results" render={(props) => getNavRoute(<ResultsForm />)} exact /> */}
                    
                    <Route path="/admin/review" render={(props) => getAdminNavRoute(<PhysicianHomePage />, props)} exact />
                    <Route path="/admin" render={(props)=> getAdminNavRoute(<AdminHomePage />, props)} exact /> 
                    {/* <Route path="/admin/CheckIn" render={(props) => getAdminNavRoute(<CheckIn />, props)} exact /> */}
                    {/* <Route path="/admin/Submission" render={(props) => getAdminNavRoute(<WalkinForm />, props)} exact /> */}
                    {/* <Route path="/admin/RapidTest" render={(props) => getAdminNavRoute(<RapidTestManagement />, props)} exact /> */}
                    {/* <Route path="/admin/Schedules" render={(props) => getAdminNavRoute(<Schedules />, props)} exact /> */}
                    {/* <Route path="/admin/Alert" render={(props) => getAdminNavRoute(<Alert />, props)} exact /> */}
                    {/* <Route path="/admin/Samples" render={(props) => getAdminNavRoute(<SamplesReport />, props)} exact /> */}
                    {/* <Route path="/admin/InternalSampleGenerator" render={(props) => getAdminNavRoute(<FakeSampleGenerator />, props)} exact /> */}
                    <Route path="/admin/AuditLogs" render={(props) => getAdminNavRoute(<AuditLogs />, props)} exact />
                    <Route path="/admin/Facility" render={(props) => getAdminNavRoute(<Facility />, props)} exact />
                    <Route path="/admin/User" render={(props) => getAdminNavRoute(<UserManagement />, props)} exact />
                    {/* <Route path="/admin/Test" render={(props) => getAdminNavRoute(<TestManagement />, props)} exact /> */}
                    <Route path="/admin/Service" render={(props) => getAdminNavRoute(<ServicesManagement />, props)} exact />
                    <Route path="/admin/Patient" render={(props) => getAdminNavRoute(<PatientManagement />, props)} exact />
                    {/* <Route path="/admin/CustomFields" render={(props) =>  getAdminNavRoute(<CustomFields/>, props)} exact/> */}
                    {/* <Route path="/admin/PatientFields" render={(props) =>  getAdminNavRoute(<PatientFields/>, props)} exact/> */}
                    <Route path="/admin/Communication" render={(props) => getAdminNavRoute(<CommunicationTemplates />, props)} exact />
                    <Route path="/admin/HL7Messages" render={(props) => getAdminNavRoute(<HL7MessagesManagement />, props)} exact />
                    {/* <Route path="/admin/Result" render={(props) =>  getAdminNavRoute(<ResultsManagement/>, props)} exact/> */}
                    <Route path="/admin/Banner" render={(props) =>  getAdminNavRoute(<BannerManagement/>, props)} exact/>
                    {/* <Route path="/admin/ReportSettings" render={(props) => getAdminNavRoute(<ReportSettings />, props)} exact /> */}
                    <Route path="/admin/PasswordRequirements" render={(props)=> getAdminNavRoute(<PasswordSettings />, props)} exact />
                    {/* <Route path="/admin/Pricing" render={(props)=> getAdminNavRoute(<PricingManagement />, props)} exact /> */}
                    {/* <Route path="/admin/Discounts" render={(props)=> getAdminNavRoute(<DiscountManagement />, props)} exact /> */}
                    <Route path="/admin/File" render={(props)=> getAdminNavRoute(<DocumentUpload />, props)} exact />
                    <Route path="/admin/ExemptionRecords" render={(props) => getAdminNavRoute(<ExemptionRecords/>, props)} exact />
                    {/* <Route path="/admin/Transactions" render={(props)=> getAdminNavRoute(<Transactions />, props)} exact /> */}
                    {/* <Route path="/confirmation" render={(props) => getNavRoute(<Confirmation />)} exact />
                    <Route path="/checkin" render={(props) => <PatientCheckIn />} exact />
                    <Route path="/retrieve" render={(props) => getNavRoute(<RetrieveConfirmation />)} exact /> */}
                    <Route path="/logout" render={(props)=> getNavRoute(<LoggedOut /> )} exact />
                    <Route path="/loggedout" render={(props) => getNavRoute(<LoggedOut />)} exact />
                    <Route path="/login" render={(props)=> getLoginRoute(<Login form_action={'login'} />) } exact />
                    <Route path="/register" render={(props) => getLoginRoute(<Login form_action={'register'}/>)} exact />
                    <Route path="/forgotPassword" render={(props) => getLoginRoute(<ForgotPassword />)} exact/>
                    <Route path="/__/auth/:action" render={(props)=> getLoginRoute(<Login form_action={props.match.params.action} />)} />
                    <Redirect to={"/admin"} />
                </Switch>
            </React.Fragment>
        </BrowserRouter>);
}

export default App;
