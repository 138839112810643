// import firebase from "firebase/compat/app";
import { useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { User } from "firebase/auth"
import { auth } from "../FirebaseConfig";
export const AuthProvider: React.FC = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);
    const pathname = window.location.pathname

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async(firebaseUser) => {
            if(firebaseUser){
                setUser(firebaseUser)
            }
            if(pathname.includes('/admin')) {
                if(!firebaseUser){ 
                    window['location'] = "/login" as unknown as Location
                }
            }
        });

        return unsubscribe;
    }, [pathname]);

    return <AuthContext.Provider value={user}>{children}</AuthContext.Provider> 
};